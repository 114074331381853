import { useParams } from "react-router-dom";
import BreadHeader from "../../components/breadheader";
import MainDashboard from "../../components/dashboards/main_dashboard";
import UserSearchesTable from "../../components/tables/UserSearchesTable";
import { useEffect, useState } from "react";
import { axiosPrivateRiskScore } from "../../api/axiosPrivate";

export default function UserSearches() {
  const [loading, setLoading] = useState(true);
  const [scoreList, setScoreList] = useState<any>();

  const { userId } = useParams();
  const URL = `/v1/location_scores/${userId}/scores?page=1&items_per_page_=50`;
  async function getSearches() {
    axiosPrivateRiskScore<any>({ method: "GET", url: URL })
      .then((data) => {
        setLoading(false);
        setScoreList(data?.data?.scores);
        // console.log("get score data: ", data);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }
  useEffect(() => {
    getSearches();
  }, []);
  return (
    <MainDashboard>
      <div>
        <BreadHeader title="User's Searches" />

        {loading ? (
          <>Loading...</>
        ) : (
          <>
            <UserSearchesTable scoreList={scoreList} />
          </>
        )}
      </div>
    </MainDashboard>
  );
}
