import { CreditCard, FileX, SaveAll, Search } from "lucide-react";

const CompanyReportInfo = ({ activityData }: any) => {
  // Calculate the difference in months between startDate and endDate

  // console.log(activityData?.credit_status, "from the bottom");
  return (
    <>
      {/* Invoice */}
      <div id="printablediv" className="max-w-[85rem]  mx-auto my-4 sm:my-10 ">
        {/* Grid */}

        <div className="grid md:grid-cols-1 gap-3 pb-5 px-4 sm:px-6 lg:px-8  border-b border-gray-200 dark:border-gray-700">
          <h1 className="block text-2xl mb-3 font-bold text-gray-800 sm:text-2xl dark:text-white">
            Detailed Report for {activityData.name}
          </h1>
          <div className="grid grid-cols-2 gap-4 mb-7">
            <div className="flex items-center p-5 md:p-5 gap-8 border shadow-sm rounded-xl dark:bg-[#305934] dark:border-gray-700 dark:shadow-slate-700/[.7] bg-[#DDF5DF]">
              <div className="icon">
                <CreditCard size={40} />
              </div>
              <div>
                <h3 className="text-xl font-bold text-gray-800 dark:text-white">
                  Total Search Credits
                </h3>
                <p className="mt-2 text-gray-500 dark:text-gray-400">
                  {activityData?.max_search_credits}
                </p>
              </div>
            </div>
            <div className="flex items-center p-5 md:p-5 gap-8 border shadow-sm rounded-xl dark:bg-[#968ff8b5] dark:border-gray-700 dark:shadow-slate-700/[.7] bg-[#7e74fb26]">
              <div className="icon">
                <Search size={40} />
              </div>
              <div>
                <h3 className="text-xl font-bold text-gray-800 dark:text-white">
                  Remaining Search Credits
                </h3>
                <p className="mt-2 text-gray-500 dark:text-gray-400">
                  {activityData?.max_search_credits -
                    activityData?.used_search_credits}
                </p>
              </div>
            </div>

            <div className="flex items-center p-5 md:p-5 gap-8 border shadow-sm rounded-xl dark:bg-[#ff6f3e5e] dark:border-gray-700 dark:shadow-slate-700/[.7] bg-[#fe541936]">
              <div className="icon">
                <SaveAll size={40} />
              </div>
              <div>
                <h3 className="text-xl font-bold text-gray-800 dark:text-white">
                  Saved Searches
                </h3>
                <p className="mt-2 text-gray-500 dark:text-gray-400">
                  {activityData?.saved_search_results}
                </p>
              </div>
            </div>
            <div className="flex items-center p-5 md:p-5 gap-8 border shadow-sm rounded-xl dark:bg-[#78ceed78] dark:border-gray-700 dark:shadow-slate-700/[.7] bg-[#5bc8ef29]">
              <div className="icon">
                <FileX size={40} />
              </div>
              <div>
                <h3 className="text-xl font-bold text-gray-800 dark:text-white">
                  Unsaved Searches
                </h3>
                <p className="mt-2 text-gray-500 dark:text-gray-400">
                  {activityData?.unsaved_search_results}
                </p>
              </div>
            </div>
            <div className="flex items-center p-5 md:p-5 gap-8 border shadow-sm rounded-xl dark:bg-[#305934] dark:border-gray-700 dark:shadow-slate-700/[.7] bg-[#DDF5DF]">
              <div className="icon">
                <CreditCard size={40} />
              </div>
              <div>
                <h3 className="text-xl font-bold text-gray-800 dark:text-white">
                  Credit status
                </h3>
                <p className="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-300 text-green-800 dark:bg-green-900 dark:text-green-200">
                  {activityData?.credit_status}
                </p>
              </div>
            </div>
            <div className="flex items-center p-5 md:p-5 gap-8 border shadow-sm rounded-xl dark:bg-[#968ff8b5] dark:border-gray-700 dark:shadow-slate-700/[.7] bg-[#7e74fb26]">
              <div className="icon">
                <Search size={40} />
              </div>
              <div>
                <h3 className="text-xl font-bold text-gray-800 dark:text-white">
                  Most searched Crop
                </h3>
                <p className="mt-2 text-gray-500 dark:text-gray-400">
                  {activityData.top_crops_searched}
                </p>
              </div>
            </div>

            <div className="flex items-center p-5 md:p-5 gap-8 border shadow-sm rounded-xl dark:bg-[#ff6f3e5e] dark:border-gray-700 dark:shadow-slate-700/[.7] bg-[#fe541936]">
              <div className="icon">
                <SaveAll size={40} />
              </div>
              <div>
                <h3 className="text-xl font-bold text-gray-800 dark:text-white">
                  Overall risk score
                </h3>
                <p className="mt-2 text-gray-500 dark:text-gray-400">
                  {Math.floor(activityData?.avg_risk_all)}
                </p>
              </div>
            </div>
            <div className="flex items-center p-5 md:p-5 gap-8 border shadow-sm rounded-xl dark:bg-[#78ceed78] dark:border-gray-700 dark:shadow-slate-700/[.7] bg-[#5bc8ef29]">
              <div className="icon">
                <FileX size={40} />
              </div>
              <div>
                <h3 className="text-xl font-bold text-gray-800 dark:text-white">
                  Saved risk score
                </h3>
                <p className="mt-2 text-gray-500 dark:text-gray-400">
                  {Math.floor(activityData?.avg_risk_saved)}
                </p>
              </div>
            </div>
          </div>
          {/* Col */}

          {/* Col */}
        </div>
        {/* End Grid */}
        <div className="pt-8 px-4 sm:px-6 lg:px-8">
          {/* Grid */}
          <div className="flex justify-end items-center  border-gray-200 dark:border-gray-700">
            <div>
              <img
                alt="Bitpulse"
                className="w-40 mx-auto"
                src="/img/logo.png"
              />
            </div>
          </div>
          {/* End Grid */}
          {/* Table */}

          {/* End Table */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-5"></div>

          <div className="mt-8">
            <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
              Thank you!
            </h4>
            <p className="text-gray-500">
              If you have any questions concerning this report, use the
              following contact information:
            </p>
            <div className="mt-2">
              <p className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                info@adapta.earth
              </p>
            </div>
          </div>
          <p className="mt-5 text-sm text-gray-500">© 2023 ADAPTA.</p>
        </div>
      </div>
      {/* End Invoice */}
    </>
  );
};

export default CompanyReportInfo;
