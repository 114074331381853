//@ts-ignore
// import ReactApexChart from "react-apexcharts";
// import GaugeComponent from "react-gauge-component";
import { useEffect, useState } from "react";
import MiniChart from "./MiniChart";
import GradientBarChart from "./GradientBarchart";
import { useTranslation } from "react-i18next";

interface GaugeChartProps {
  pillar: string;
  rainfall_risk: number | undefined;
  temperature_risk: number | undefined;
  drought_risk: number | undefined;
  composite_climate_risk: number | undefined;
  categories: string[];
  tooltip?: string;
  composite?: boolean;
}

const TopGauge: React.FC<GaugeChartProps> = ({
  pillar,
  rainfall_risk,
  temperature_risk,
  drought_risk,
  composite_climate_risk,
  categories,
  tooltip,
  composite,
  //@ts-ignore
  idle,
}) => {
  // const temperatureScore = composite_climate_risk;

  // Check and modify rainfall_risk and temperature_risk if they are 0

  // const modifiedRainfallRisk =
  //   rainfall_risk && rainfall_risk < 10 ? 10 : rainfall_risk;
  // const modifiedTemperatureRisk =
  //   temperature_risk && temperature_risk < 10 ? 10 : temperature_risk;
  // const modifiedDroughtRisk =
  //   drought_risk && drought_risk < 10 ? 10 : drought_risk;
  const { t } = useTranslation("home");
  const modifiedRainfallRisk = rainfall_risk === 0 ? 0 : rainfall_risk;
  const modifiedTemperatureRisk = temperature_risk === 0 ? 0 : temperature_risk;
  const modifiedDroughtRisk = drought_risk === 0 ? 0 : drought_risk;

  const initialSeries = [
    {
      data: [
        modifiedRainfallRisk,
        modifiedTemperatureRisk,
        modifiedDroughtRisk,
      ], // Replace with your data values
    },
  ];

  const [series, setSeries] = useState(initialSeries);
  useEffect(() => {
    setSeries(initialSeries);
    // window.dispatchEvent(new Event("resize"));
    // console.log("resizee");
  }, [composite_climate_risk]);

  return (
    <>
      {/* Card */}
      <div className="group shadow-md flex flex-col p-6  bg-[#ffffff] border border-gray-200  rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]">
        <h3 className="font-semibold pb-2 text-base leading-none tracking-tight px-4">
          {pillar} {t("RISK")}
        </h3>
        {tooltip ? (
          <div className=" relative md:flex gap-[2%] lg:gap-[0px] justify-between flex-wrap">
            <div className="flex justify-center items-center w-100 md:w-[34%] lg:w-1/3">
              <MiniChart
                smallGauge
                idle={idle}
                // @ts-ignore
                indexScore={composite_climate_risk?.toFixed(0)}
                width={100}
              />
            </div>
            <div className="pr-3 pt-3 w-100 md:w-[64%] lg:w-[50%] ">
              <div className="horizontal-bar-chart" id="chart">
                <GradientBarChart
                  //@ts-ignore
                  idle={idle}
                  composite={composite}
                  categories={categories}
                  scores={series}
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="flex justify-center items-center">
              <MiniChart
                // @ts-ignore
                indexScore={composite_climate_risk?.toFixed(0)}
                width={120}
              />
            </div>

            <div className="pr-3 pt-3">
              <div className="horizontal-bar-chart" id="chart">
                <GradientBarChart
                  categories={categories}
                  scores={series}
                  //@ts-ignore
                  idle={idle}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default TopGauge;
