import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axiosPrivate, { axiosPrivateOid } from "../../api/axiosPrivate";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { Company } from "../../data/teamData";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
// import { AsYouType } from "libphonenumber-js";
import { PhoneInput } from "react-international-phone";
import { search, sortedData } from "../../utils";
import { useTranslation } from "react-i18next";
const schema = yup.object().shape({
  contact_name: yup.string().required("Name is required"),
  contact_email: yup.string().required("Email is requried"),
  contact_phone_number: yup.string().required("Phone number is requried"),
  // country: yup.string(),
  max_admins: yup.number().required("Please add max admins"),
  max_members: yup.number().required("Please specify max members"),
  max_search_credits: yup
    .number()
    .required("Please specify max search credits"),
  website: yup.string().required("Please provide the website url"),
  name: yup.string().required("Please provide the name"),
});

// interface FormData {
//   name: string;
//   description: string;
// }

// interface TeamData {
//   message: string;
//   team_id: string;
// }
// interface CountryType {
//   code: string;
//   label: string;
//   phone: string;
//   suggested?: boolean;
// }

function getStatusClassName(status: string) {
  let className = "";

  if (status === "Registred") {
    className =
      "inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200";
  } else if (status === "Pending") {
    className =
      "inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-orange-100 text-orange-800 dark:bg-orange-900 dark:text-orange-200";
  } else if (status === "Suspended") {
    className =
      "inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200";
  }

  return className;
}
const URL_CREATE_TEAM = `/company/register`;
const URL_TEAM_LIST = `/company/all`;
// const countries = [
//   { label: "United States", value: "US", phoneCode: "+1" },
//   { label: "United Kingdom", value: "GB", phoneCode: "+44" },
//   // Add more countries as needed
// ];
export default function CompaniesTable() {
  const {
    register: registerForm,
    handleSubmit,
    control,
    reset,
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: {
      contact_phone_number: "",
      country: { value: null, label: null },
    },
  });

  const navigate = useNavigate();
  const [companyList] = useState<any>();
  const { t } = useTranslation("home");
  const [companyData, setCompanyData] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const countries: any = [
    { value: "AD", label: "Andorra", phoneCode: "376" },
    {
      value: "AE",
      label: "United Arab Emirates",
      phoneCode: "971",
    },
    { value: "AF", label: "Afghanistan", phoneCode: "93" },
    {
      value: "AG",
      label: "Antigua and Barbuda",
      phoneCode: "1-268",
    },
    { value: "AI", label: "Anguilla", phoneCode: "1-264" },
    { value: "AL", label: "Albania", phoneCode: "355" },
    { value: "AM", label: "Armenia", phoneCode: "374" },
    { value: "AO", label: "Angola", phoneCode: "244" },
    { value: "AQ", label: "Antarctica", phoneCode: "672" },
    { value: "AR", label: "Argentina", phoneCode: "54" },
    { value: "AS", label: "American Samoa", phoneCode: "1-684" },
    { value: "AT", label: "Austria", phoneCode: "43" },
    {
      value: "AU",
      label: "Australia",
      phoneCode: "61",
      suggested: true,
    },
    { value: "AW", label: "Aruba", phoneCode: "297" },
    { value: "AX", label: "Alland Islands", phoneCode: "358" },
    { value: "AZ", label: "Azerbaijan", phoneCode: "994" },
    {
      value: "BA",
      label: "Bosnia and Herzegovina",
      phoneCode: "387",
    },
    { value: "BB", label: "Barbados", phoneCode: "1-246" },
    { value: "BD", label: "Bangladesh", phoneCode: "880" },
    { value: "BE", label: "Belgium", phoneCode: "32" },
    { value: "BF", label: "Burkina Faso", phoneCode: "226" },
    { value: "BG", label: "Bulgaria", phoneCode: "359" },
    { value: "BH", label: "Bahrain", phoneCode: "973" },
    { value: "BI", label: "Burundi", phoneCode: "257" },
    { value: "BJ", label: "Benin", phoneCode: "229" },
    { value: "BL", label: "Saint Barthelemy", phoneCode: "590" },
    { value: "BM", label: "Bermuda", phoneCode: "1-441" },
    { value: "BN", label: "Brunei Darussalam", phoneCode: "673" },
    { value: "BO", label: "Bolivia", phoneCode: "591" },
    { value: "BR", label: "Brazil", phoneCode: "55" },
    { value: "BS", label: "Bahamas", phoneCode: "1-242" },
    { value: "BT", label: "Bhutan", phoneCode: "975" },
    { value: "BV", label: "Bouvet Island", phoneCode: "47" },
    { value: "BW", label: "Botswana", phoneCode: "267" },
    { value: "BY", label: "Belarus", phoneCode: "375" },
    { value: "BZ", label: "Belize", phoneCode: "501" },
    {
      value: "CA",
      label: "Canada",
      phoneCode: "1",
      suggested: true,
    },
    {
      value: "CC",
      label: "Cocos (Keeling) Islands",
      phoneCode: "61",
    },
    {
      value: "CD",
      label: "Congo, Democratic Republic of the",
      phoneCode: "243",
    },
    {
      value: "CF",
      label: "Central African Republic",
      phoneCode: "236",
    },
    {
      value: "CG",
      label: "Congo, Republic of the",
      phoneCode: "242",
    },
    { value: "CH", label: "Switzerland", phoneCode: "41" },
    { value: "CI", label: "Cote d'Ivoire", phoneCode: "225" },
    { value: "CK", label: "Cook Islands", phoneCode: "682" },
    { value: "CL", label: "Chile", phoneCode: "56" },
    { value: "CM", label: "Cameroon", phoneCode: "237" },
    { value: "CN", label: "China", phoneCode: "86" },
    { value: "CO", label: "Colombia", phoneCode: "57" },
    { value: "CR", label: "Costa Rica", phoneCode: "506" },
    { value: "CU", label: "Cuba", phoneCode: "53" },
    { value: "CV", label: "Cape Verde", phoneCode: "238" },
    { value: "CW", label: "Curacao", phoneCode: "599" },
    { value: "CX", label: "Christmas Island", phoneCode: "61" },
    { value: "CY", label: "Cyprus", phoneCode: "357" },
    { value: "CZ", label: "Czech Republic", phoneCode: "420" },
    {
      value: "DE",
      label: "Germany",
      phoneCode: "49",
      suggested: true,
    },
    { value: "DJ", label: "Djibouti", phoneCode: "253" },
    { value: "DK", label: "Denmark", phoneCode: "45" },
    { value: "DM", label: "Dominica", phoneCode: "1-767" },
    {
      value: "DO",
      label: "Dominican Republic",
      phoneCode: "1-809",
    },
    { value: "DZ", label: "Algeria", phoneCode: "213" },
    { value: "EC", label: "Ecuador", phoneCode: "593" },
    { value: "EE", label: "Estonia", phoneCode: "372" },
    { value: "EG", label: "Egypt", phoneCode: "20" },
    { value: "EH", label: "Western Sahara", phoneCode: "212" },
    { value: "ER", label: "Eritrea", phoneCode: "291" },
    { value: "ES", label: "Spain", phoneCode: "34" },
    { value: "ET", label: "Ethiopia", phoneCode: "251" },
    { value: "FI", label: "Finland", phoneCode: "358" },
    { value: "FJ", label: "Fiji", phoneCode: "679" },
    {
      value: "FK",
      label: "Falkland Islands (Malvinas)",
      phoneCode: "500",
    },
    {
      value: "FM",
      label: "Micronesia, Federated States of",
      phoneCode: "691",
    },
    { value: "FO", label: "Faroe Islands", phoneCode: "298" },
    {
      value: "FR",
      label: "France",
      phoneCode: "33",
      suggested: true,
    },
    { value: "GA", label: "Gabon", phoneCode: "241" },
    { value: "GB", label: "United Kingdom", phoneCode: "44" },
    { value: "GD", label: "Grenada", phoneCode: "1-473" },
    { value: "GE", label: "Georgia", phoneCode: "995" },
    { value: "GF", label: "French Guiana", phoneCode: "594" },
    { value: "GG", label: "Guernsey", phoneCode: "44" },
    { value: "GH", label: "Ghana", phoneCode: "233" },
    { value: "GI", label: "Gibraltar", phoneCode: "350" },
    { value: "GL", label: "Greenland", phoneCode: "299" },
    { value: "GM", label: "Gambia", phoneCode: "220" },
    { value: "GN", label: "Guinea", phoneCode: "224" },
    { value: "GP", label: "Guadeloupe", phoneCode: "590" },
    { value: "GQ", label: "Equatorial Guinea", phoneCode: "240" },
    { value: "GR", label: "Greece", phoneCode: "30" },
    {
      value: "GS",
      label: "South Georgia and the South Sandwich Islands",
      phoneCode: "500",
    },
    { value: "GT", label: "Guatemala", phoneCode: "502" },
    { value: "GU", label: "Guam", phoneCode: "1-671" },
    { value: "GW", label: "Guinea-Bissau", phoneCode: "245" },
    { value: "GY", label: "Guyana", phoneCode: "592" },
    { value: "HK", label: "Hong Kong", phoneCode: "852" },
    {
      value: "HM",
      label: "Heard Island and McDonald Islands",
      phoneCode: "672",
    },
    { value: "HN", label: "Honduras", phoneCode: "504" },
    { value: "HR", label: "Croatia", phoneCode: "385" },
    { value: "HT", label: "Haiti", phoneCode: "509" },
    { value: "HU", label: "Hungary", phoneCode: "36" },
    { value: "ID", label: "Indonesia", phoneCode: "62" },
    { value: "IE", label: "Ireland", phoneCode: "353" },
    { value: "IL", label: "Israel", phoneCode: "972" },
    { value: "IM", label: "Isle of Man", phoneCode: "44" },
    { value: "IN", label: "India", phoneCode: "91" },
    {
      value: "IO",
      label: "British Indian Ocean Territory",
      phoneCode: "246",
    },
    { value: "IQ", label: "Iraq", phoneCode: "964" },
    {
      value: "IR",
      label: "Iran, Islamic Republic of",
      phoneCode: "98",
    },
    { value: "IS", label: "Iceland", phoneCode: "354" },
    { value: "IT", label: "Italy", phoneCode: "39" },
    { value: "JE", label: "Jersey", phoneCode: "44" },
    { value: "JM", label: "Jamaica", phoneCode: "1-876" },
    { value: "JO", label: "Jordan", phoneCode: "962" },
    {
      value: "JP",
      label: "Japan",
      phoneCode: "81",
      suggested: true,
    },
    { value: "KE", label: "Kenya", phoneCode: "254" },
    { value: "KG", label: "Kyrgyzstan", phoneCode: "996" },
    { value: "KH", label: "Cambodia", phoneCode: "855" },
    { value: "KI", label: "Kiribati", phoneCode: "686" },
    { value: "KM", label: "Comoros", phoneCode: "269" },
    {
      value: "KN",
      label: "Saint Kitts and Nevis",
      phoneCode: "1-869",
    },
    {
      value: "KP",
      label: "Korea, Democratic People's Republic of",
      phoneCode: "850",
    },
    { value: "KR", label: "Korea, Republic of", phoneCode: "82" },
    { value: "KW", label: "Kuwait", phoneCode: "965" },
    { value: "KY", label: "Cayman Islands", phoneCode: "1-345" },
    { value: "KZ", label: "Kazakhstan", phoneCode: "7" },
    {
      value: "LA",
      label: "Lao People's Democratic Republic",
      phoneCode: "856",
    },
    { value: "LB", label: "Lebanon", phoneCode: "961" },
    { value: "LC", label: "Saint Lucia", phoneCode: "1-758" },
    { value: "LI", label: "Liechtenstein", phoneCode: "423" },
    { value: "LK", label: "Sri Lanka", phoneCode: "94" },
    { value: "LR", label: "Liberia", phoneCode: "231" },
    { value: "LS", label: "Lesotho", phoneCode: "266" },
    { value: "LT", label: "Lithuania", phoneCode: "370" },
    { value: "LU", label: "Luxembourg", phoneCode: "352" },
    { value: "LV", label: "Latvia", phoneCode: "371" },
    { value: "LY", label: "Libya", phoneCode: "218" },
    { value: "MA", label: "Morocco", phoneCode: "212" },
    { value: "MC", label: "Monaco", phoneCode: "377" },
    {
      value: "MD",
      label: "Moldova, Republic of",
      phoneCode: "373",
    },
    { value: "ME", label: "Montenegro", phoneCode: "382" },
    {
      value: "MF",
      label: "Saint Martin (French part)",
      phoneCode: "590",
    },
    { value: "MG", label: "Madagascar", phoneCode: "261" },
    { value: "MH", label: "Marshall Islands", phoneCode: "692" },
    {
      value: "MK",
      label: "Macedonia, the Former Yugoslav Republic of",
      phoneCode: "389",
    },
    { value: "ML", label: "Mali", phoneCode: "223" },
    { value: "MM", label: "Myanmar", phoneCode: "95" },
    { value: "MN", label: "Mongolia", phoneCode: "976" },
    { value: "MO", label: "Macao", phoneCode: "853" },
    {
      value: "MP",
      label: "Northern Mariana Islands",
      phoneCode: "1-670",
    },
    { value: "MQ", label: "Martinique", phoneCode: "596" },
    { value: "MR", label: "Mauritania", phoneCode: "222" },
    { value: "MS", label: "Montserrat", phoneCode: "1-664" },
    { value: "MT", label: "Malta", phoneCode: "356" },
    { value: "MU", label: "Mauritius", phoneCode: "230" },
    { value: "MV", label: "Maldives", phoneCode: "960" },
    { value: "MW", label: "Malawi", phoneCode: "265" },
    { value: "MX", label: "Mexico", phoneCode: "52" },
    { value: "MY", label: "Malaysia", phoneCode: "60" },
    { value: "MZ", label: "Mozambique", phoneCode: "258" },
    { value: "NA", label: "Namibia", phoneCode: "264" },
    { value: "NC", label: "New Caledonia", phoneCode: "687" },
    { value: "NE", label: "Niger", phoneCode: "227" },
    { value: "NF", label: "Norfolk Island", phoneCode: "672" },
    { value: "NG", label: "Nigeria", phoneCode: "234" },
    { value: "NI", label: "Nicaragua", phoneCode: "505" },
    { value: "NL", label: "Netherlands", phoneCode: "31" },
    { value: "NO", label: "Norway", phoneCode: "47" },
    { value: "NP", label: "Nepal", phoneCode: "977" },
    { value: "NR", label: "Nauru", phoneCode: "674" },
    { value: "NU", label: "Niue", phoneCode: "683" },
    { value: "NZ", label: "New Zealand", phoneCode: "64" },
    { value: "OM", label: "Oman", phoneCode: "968" },
    { value: "PA", label: "Panama", phoneCode: "507" },
    { value: "PE", label: "Peru", phoneCode: "51" },
    { value: "PF", label: "French Polynesia", phoneCode: "689" },
    { value: "PG", label: "Papua New Guinea", phoneCode: "675" },
    { value: "PH", label: "Philippines", phoneCode: "63" },
    { value: "PK", label: "Pakistan", phoneCode: "92" },
    { value: "PL", label: "Poland", phoneCode: "48" },
    {
      value: "PM",
      label: "Saint Pierre and Miquelon",
      phoneCode: "508",
    },
    { value: "PN", label: "Pitcairn", phoneCode: "870" },
    { value: "PR", label: "Puerto Rico", phoneCode: "1" },
    {
      value: "PS",
      label: "Palestine, State of",
      phoneCode: "970",
    },
    { value: "PT", label: "Portugal", phoneCode: "351" },
    { value: "PW", label: "Palau", phoneCode: "680" },
    { value: "PY", label: "Paraguay", phoneCode: "595" },
    { value: "QA", label: "Qatar", phoneCode: "974" },
    { value: "RE", label: "Reunion", phoneCode: "262" },
    { value: "RO", label: "Romania", phoneCode: "40" },
    { value: "RS", label: "Serbia", phoneCode: "381" },
    { value: "RU", label: "Russian Federation", phoneCode: "7" },
    { value: "RW", label: "Rwanda", phoneCode: "250" },
    { value: "SA", label: "Saudi Arabia", phoneCode: "966" },
    { value: "SB", label: "Solomon Islands", phoneCode: "677" },
    { value: "SC", label: "Seychelles", phoneCode: "248" },
    { value: "SD", label: "Sudan", phoneCode: "249" },
    { value: "SE", label: "Sweden", phoneCode: "46" },
    { value: "SG", label: "Singapore", phoneCode: "65" },
    { value: "SH", label: "Saint Helena", phoneCode: "290" },
    { value: "SI", label: "Slovenia", phoneCode: "386" },
    {
      value: "SJ",
      label: "Svalbard and Jan Mayen",
      phoneCode: "47",
    },
    { value: "SK", label: "Slovakia", phoneCode: "421" },
    { value: "SL", label: "Sierra Leone", phoneCode: "232" },
    { value: "SM", label: "San Marino", phoneCode: "378" },
    { value: "SN", label: "Senegal", phoneCode: "221" },
    { value: "SO", label: "Somalia", phoneCode: "252" },
    { value: "SR", label: "Suriname", phoneCode: "597" },
    { value: "SS", label: "South Sudan", phoneCode: "211" },
    {
      value: "ST",
      label: "Sao Tome and Principe",
      phoneCode: "239",
    },
    { value: "SV", label: "El Salvador", phoneCode: "503" },
    {
      value: "SX",
      label: "Sint Maarten (Dutch part)",
      phoneCode: "1-721",
    },
    {
      value: "SY",
      label: "Syrian Arab Republic",
      phoneCode: "963",
    },
    { value: "SZ", label: "Swaziland", phoneCode: "268" },
    {
      value: "TC",
      label: "Turks and Caicos Islands",
      phoneCode: "1-649",
    },
    { value: "TD", label: "Chad", phoneCode: "235" },
    {
      value: "TF",
      label: "French Southern Territories",
      phoneCode: "262",
    },
    { value: "TG", label: "Togo", phoneCode: "228" },
    { value: "TH", label: "Thailand", phoneCode: "66" },
    { value: "TJ", label: "Tajikistan", phoneCode: "992" },
    { value: "TK", label: "Tokelau", phoneCode: "690" },
    { value: "TL", label: "Timor-Leste", phoneCode: "670" },
    { value: "TM", label: "Turkmenistan", phoneCode: "993" },
    { value: "TN", label: "Tunisia", phoneCode: "216" },
    { value: "TO", label: "Tonga", phoneCode: "676" },
    { value: "TR", label: "Turkey", phoneCode: "90" },
    {
      value: "TT",
      label: "Trinidad and Tobago",
      phoneCode: "1-868",
    },
    { value: "TV", label: "Tuvalu", phoneCode: "688" },
    {
      value: "TW",
      label: "Taiwan",
      phoneCode: "886",
    },
    {
      value: "TZ",
      label: "United Republic of Tanzania",
      phoneCode: "255",
    },
    { value: "UA", label: "Ukraine", phoneCode: "380" },
    { value: "UG", label: "Uganda", phoneCode: "256" },
    {
      value: "US",
      label: "United States",
      phoneCode: "1",
      suggested: true,
    },
    { value: "UY", label: "Uruguay", phoneCode: "598" },
    { value: "UZ", label: "Uzbekistan", phoneCode: "998" },
    {
      value: "VA",
      label: "Holy See (Vatican City State)",
      phoneCode: "379",
    },
    {
      value: "VC",
      label: "Saint Vincent and the Grenadines",
      phoneCode: "1-784",
    },
    { value: "VE", label: "Venezuela", phoneCode: "58" },
    {
      value: "VG",
      label: "British Virgin Islands",
      phoneCode: "1-284",
    },
    {
      value: "VI",
      label: "US Virgin Islands",
      phoneCode: "1-340",
    },
    { value: "VN", label: "Vietnam", phoneCode: "84" },
    { value: "VU", label: "Vanuatu", phoneCode: "678" },
    { value: "WF", label: "Wallis and Futuna", phoneCode: "681" },
    { value: "WS", label: "Samoa", phoneCode: "685" },
    { value: "XK", label: "Kosovo", phoneCode: "383" },
    { value: "YE", label: "Yemen", phoneCode: "967" },
    { value: "YT", label: "Mayotte", phoneCode: "262" },
    { value: "ZA", label: "South Africa", phoneCode: "27" },
    { value: "ZM", label: "Zambia", phoneCode: "260" },
    { value: "ZW", label: "Zimbabwe", phoneCode: "263" },
  ];

  useEffect(() => {
    getCompanyData();
  }, []);
  const loadTeams = () => {
    axiosPrivateOid<any>({ method: "GET", url: URL_TEAM_LIST })
      .then((data) => {
        // console.log(data, "data");
        // setCompantList(data.teams);
        setCompanyData(data.data.data);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: t("Oops..."),
          text: t("Something went wrong!"),
        });
        console.error("API Error:", error);
      });
  };

  const deleteTeam = (teamId: string) => {
    const URL_DELETE_TEAM = `company/delete/${teamId}`;
    Swal.fire({
      title: t("Do you want to delete the team?"),
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: t("Delete"),
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosPrivateOid<any>({ method: "DELETE", url: URL_DELETE_TEAM })
          .then(() => {
            toast.success(t("Team Deleted Successfully"));
            loadTeams();
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: t("Not Allowed."),
              text: t("Please delete all company's users first"),
            });
            console.error("API Error:", error);
          });
      } else if (result.isDenied) {
        return;
      }
    });
  };

  const suspendTeam = (teamId: string) => {
    const URL_SUSPEND_TEAM = `/team_back_office/v1/${teamId}/suspend_team`;
    Swal.fire({
      title: t("Do you want to Suspend the team?"),
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: t("Suspend"),
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        axiosPrivate<Company>({ method: "PUT", url: URL_SUSPEND_TEAM })
          .then(() => {
            toast.success("Team Suspended Successfully");
            loadTeams();
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: t("Not Authorized."),
              text: t("You are not Authorized to access this Section"),
            });
            console.error("API Error:", error);
          });
      } else if (result.isDenied) {
        return;
      }
    });
  };

  const onSubmit = async (data: any) => {
    // console.log("dun", data);

    return axiosPrivateOid<any>({
      method: "POST",
      url: URL_CREATE_TEAM,
      data: data,
    })
      .then((data) => {
        toast.success(data.message);
        toggleModal();
        loadTeams();
      })
      .catch((error) => {
        console.error("API Error:", error);
        toast.error("Something went wrong!");
      });
  };

  async function getCompanyData() {
    axiosPrivateOid<Company>({ method: "GET", url: URL_TEAM_LIST })
      .then((data: any) => {
        // console.log(data.data.data, "dataof");
        // setCompantList(data);
        setCompanyData(data?.data?.data);
        return data;
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: t("Not Authorized."),
          text: t("You are not Authorized to access this Section"),
        });
        console.error("API Error:", error);
      });
  }

  const role = localStorage.getItem("empType");
  const toggleModal = () => {
    reset();
    setIsModalOpen((prev) => !prev);
  };
  // console.log("com", companyData);
  // const countryOptions = Object.entries(countryCodes).map(([code, name]) => (
  //   <option key={code} value={code}>
  //     {code} - {name}
  //   </option>
  // ));
  return (
    <>
      {/* Card */}
      <div className="flex relative flex-col">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-slate-900 dark:border-gray-700">
              {/* Header */}
              <div className=" p-3 grid gap-3 md:flex md:justify-between md:items-center border-gray-200 dark:border-gray-700">
                <div>
                  {/* Input */}
                  <div className="sm:col-span-1">
                    <label
                      htmlFor="hs-as-table-product-review-search"
                      className="sr-only"
                    >
                      {t("Search...")}
                    </label>
                    <div className="relative lg:w-64 xl:w-96">
                      <input
                        onChange={(e) => setSearchValue(e.target.value)}
                        type="text"
                        id="hs-as-table-product-review-search"
                        name="hs-as-table-product-review-search"
                        className="py-2 px-3  pl-11 bg-[#F2F2F2] block border-none w-full border border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                        placeholder={t("Search...")}
                      />
                      <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none pl-4">
                        <svg
                          className="h-4 w-4 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  {/* End Input */}
                </div>
                <div>
                  <div className="inline-flex gap-x-2">
                    <div
                      className="hs-dropdown relative inline-block [--placement:bottom-right]"
                      data-hs-dropdown-auto-close="inside"
                    >
                      <button
                        id="hs-as-table-table-filter-dropdown"
                        type="button"
                        className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                      >
                        <svg
                          className="w-3 h-3"
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                        </svg>
                        {t("Filter")}
                      </button>
                      <div
                        className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden divide-y divide-gray-200 min-w-[12rem] z-10 bg-white shadow-md rounded-lg mt-2 dark:divide-gray-700 dark:bg-gray-800 dark:border dark:border-gray-700"
                        aria-labelledby="hs-as-table-table-filter-dropdown"
                      >
                        <div className="divide-y divide-gray-200 dark:divide-gray-700">
                          <label
                            htmlFor="hs-as-filters-dropdown-all"
                            className="flex py-2.5 px-3"
                          >
                            <input
                              type="checkbox"
                              className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                              id="hs-as-filters-dropdown-all"
                            />
                            <span className="ml-3 text-sm text-gray-800 dark:text-gray-200">
                              {t("All")}
                            </span>
                          </label>
                          <label
                            htmlFor="hs-as-filters-dropdown-paid"
                            className="flex py-2.5 px-3"
                          >
                            <input
                              type="checkbox"
                              className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                              id="hs-as-filters-dropdown-paid"
                            />
                            <span className="ml-3 text-sm text-gray-800 dark:text-gray-200">
                              {t("Accepted")}
                            </span>
                          </label>
                          <label
                            htmlFor="hs-as-filters-dropdown-pending"
                            className="flex py-2.5 px-3"
                          >
                            <input
                              type="checkbox"
                              className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                              id="hs-as-filters-dropdown-pending"
                            />
                            <span className="ml-3 text-sm text-gray-800 dark:text-gray-200">
                              {t("Pending")}
                            </span>
                          </label>
                          <label
                            htmlFor="hs-as-filters-dropdown-declined"
                            className="flex py-2.5 px-3"
                          >
                            <input
                              type="checkbox"
                              className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                              id="hs-as-filters-dropdown-declined"
                            />
                            <span className="ml-3 text-sm text-gray-800 dark:text-gray-200">
                              {t("Declined")}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    {role === "super_admin" || role === "developer" ? (
                      <button
                        className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                        onClick={toggleModal}
                      >
                        <svg
                          className="w-3 h-3"
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M2.63452 7.50001L13.6345 7.5M8.13452 13V2"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                          />
                        </svg>
                        {t("Register Company")}
                      </button>
                    ) : null}

                    <div
                      id="register-modal"
                      className={`hs-overlay w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto ${
                        isModalOpen ? "open" : "hidden"
                      }`}
                    >
                      <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
                        <div className="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]">
                          <div className="flex justify-between items-center py-3 px-4 border-b dark:border-gray-700">
                            <h3 className="font-bold text-gray-800 dark:text-white">
                              {t("Register")}
                            </h3>
                            <button
                              type="button"
                              className="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
                              data-hs-overlay="#hs-focus-management-modal"
                              onClick={toggleModal}
                            >
                              <span className="sr-only">{t("Close")}</span>
                              <svg
                                className="w-3.5 h-3.5"
                                width={8}
                                height={8}
                                viewBox="0 0 8 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </button>
                          </div>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="p-4 overflow-y-auto">
                              <label
                                htmlFor="input-label"
                                className="block text-sm font-medium mb-2 dark:text-white"
                              >
                                {t("Company's name")}
                              </label>
                              <input
                                type="text"
                                id="input-label"
                                className="py-3 px-4 border block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                                placeholder="Name"
                                {...registerForm("name")}
                              />
                            </div>
                            <div className="p-4 overflow-y-auto">
                              <label
                                htmlFor="input-label"
                                className="block text-sm font-medium mb-2 dark:text-white"
                              >
                                {t("Contact name")}
                              </label>
                              <input
                                type="text"
                                id="input-label"
                                className="py-3 px-4 border block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                                placeholder="Name"
                                {...registerForm("contact_name")}
                              />
                            </div>
                            <div className="p-4">
                              <label
                                htmlFor="input-label"
                                className="block text-sm font-medium mb-2 dark:text-white"
                              >
                                {t("Country")}
                              </label>
                              {/* <input
                                type="text"
                                id="input-label"
                                {...registerForm("country")}
                                className="py-3 px-4 border block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                                placeholder="Country"
                              /> */}
                              {/* <select
                                id="country"
                                value={selectedCountry}
                                {...registerForm("country")}
                                onChange={handleCountryChange}
                              >
                                {countryOptions}
                              </select> */}
                              <Controller
                                name="country"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    className="dark:text-black"
                                    // {...registerForm("country")}
                                    {...field}
                                    //@ts-ignore
                                    //onChange={(val) => onChange(val.label)}
                                    // onChange={(value) =>
                                    //   console.log(value.label)
                                    // }
                                    options={countries.map(
                                      ({ value, label }) => ({ label, value })
                                    )}
                                    placeholder={t("Select a country...")}
                                    // isSearchable
                                  />
                                )}
                              />
                            </div>
                            <div className="p-4 overflow-y-auto">
                              <label
                                htmlFor="input-label"
                                className="block text-sm font-medium mb-2 dark:text-white"
                              >
                                {t("Email")}
                              </label>
                              <input
                                className="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                                placeholder="Email"
                                {...registerForm("contact_email")}
                              ></input>
                            </div>
                            <div className="p-4">
                              <label
                                htmlFor="input-label"
                                className="block text-sm font-medium mb-2 dark:text-white"
                              >
                                {t("Phone number")}
                              </label>
                              {/* <input
                                type="text"
                                className="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                                placeholder="Phone number"
                                {...registerForm("contact_phone_number")}
                              ></input> */}
                              {/* <input
                                type="text"
                                id="phoneNumber"
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                              /> */}
                              {/* <input
                                type="text"
                                className="py-3 px-4 border block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                                value={formatPhoneNumber()}
                                {...registerForm("contact_phone_number")}
                                placeholder="Enter phone number"
                                onChange={handlePhoneChange}
                              /> */}
                              <Controller
                                control={control}
                                name="contact_phone_number"
                                render={({ field }) => (
                                  <PhoneInput
                                    {...field}
                                    className="w-full dark:text-black"
                                    defaultCountry="ke"
                                    inputClassName="w-full"
                                  />
                                )}
                              />
                            </div>
                            <div className="p-4 overflow-y-auto">
                              <label
                                htmlFor="input-label"
                                className="block text-sm font-medium mb-2 dark:text-white"
                              >
                                {t("Max admins")}
                              </label>
                              <input
                                className="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                                placeholder="Max admin allowed"
                                {...registerForm("max_admins")}
                              ></input>
                            </div>
                            <div className="p-4 overflow-y-auto">
                              <label
                                htmlFor="input-label"
                                className="block text-sm font-medium mb-2 dark:text-white"
                              >
                                {t("Max members")}
                              </label>
                              <input
                                className="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                                placeholder="Max member allowed"
                                {...registerForm("max_members")}
                              ></input>
                            </div>
                            <div className="p-4 overflow-y-auto">
                              <label
                                htmlFor="input-label"
                                className="block text-sm font-medium mb-2 dark:text-white"
                              >
                                {t("Max search credit allowed")}
                              </label>
                              <input
                                className="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                                placeholder="Max search credit allowed"
                                {...registerForm("max_search_credits")}
                              ></input>
                            </div>
                            <div className="p-4 overflow-y-auto">
                              <label
                                htmlFor="input-label"
                                className="block text-sm font-medium mb-2 dark:text-white"
                              >
                                {t("Website")}
                              </label>
                              <input
                                className="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                                placeholder="Website"
                                {...registerForm("website")}
                              ></input>
                            </div>

                            <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700">
                              <button
                                type="button"
                                className="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                                onClick={toggleModal}
                              >
                                {t("Close")}
                              </button>
                              <button
                                // onClick={() => handleSubmit(onsubmit)}
                                type="submit"
                                className="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                              >
                                {t("Register")}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Header */}
              {/* Table */}
              <table className="min-w-full mt-8 divide-gray-200 dark:divide-gray-700">
                <thead className="bg-gray-50 dark:bg-slate-800">
                  <tr>
                    <th scope="col" className="pl-6 py-3 text-left">
                      <label
                        htmlFor="hs-at-with-checkboxes-main"
                        className="flex"
                      >
                        <input
                          type="checkbox"
                          className="shrink-0 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                          id="hs-at-with-checkboxes-main"
                        />
                        <span className="sr-only">Checkbox</span>
                      </label>
                    </th>
                    <th
                      scope="col"
                      className="pl-6 lg:pl-3 xl:pl-0 pr-6 py-3 text-left"
                    >
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                          {t("Company's name")}
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                          {t("Country")}
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-8 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                          {t("Status")}
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-6 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                          {t("Phone no.")}
                        </span>
                      </div>
                    </th>
                    <th scope="col" className="px-14 py-3 text-left">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                          {t("Action")}
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                {companyData ? (
                  <>
                    <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                      {search(
                        sortedData(companyData, "name"),
                        searchValue,
                        "name",
                        "country"
                      )?.map((data: any) => (
                        <tr>
                          <td className="h-px w-px whitespace-nowrap">
                            <div className="pl-6 py-3">
                              <label
                                htmlFor="hs-at-with-checkboxes-1"
                                className="flex"
                              >
                                <input
                                  type="checkbox"
                                  className="shrink-0 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                  id="hs-at-with-checkboxes-1"
                                />
                                <span className="sr-only">Checkbox</span>
                              </label>
                            </div>
                          </td>
                          <td className="h-px w-px whitespace-nowrap">
                            <div className="pl-6 lg:pl-3 xl:pl-0 pr-6 py-3">
                              <div className="flex items-center gap-x-3">
                                <span className="inline-flex items-center justify-center h-[2.375rem] w-[2.375rem] rounded-full bg-blue-300 dark:bg-blue-700">
                                  <span className="font-medium text-blue-800 leading-none dark:text-blue-200">
                                    {data.name.charAt(0)}
                                  </span>
                                </span>
                                <div className="grow">
                                  <span className="block text-sm font-semibold text-gray-800 dark:text-gray-200">
                                    {data.name}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          {/* <td className="h-px w-px whitespace-nowrap">
                        <div className="px-6 py-3">
                          <span className="inline-flex items-centertext-sm font-semibold text-gray-800 dark:text-gray-200">
                            <Flag
                              size={24}
                              className="mr-2"
                              country={data.countryCode}
                            />
                          </span>
                          <span className="inline-flex items-centertext-sm font-semibold text-gray-800 dark:text-gray-200">
                            {data.country}
                          </span>
                        </div>
                      </td> */}

                          <td className="h-px w-px whitespace-nowrap">
                            <div className="px-6 py-3">{data.country}</div>
                          </td>

                          <td className="h-px w-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              {data.is_suspended ? (
                                <span
                                  className={getStatusClassName("Suspended")}
                                >
                                  {t("Suspended")}
                                </span>
                              ) : (
                                <span
                                  className={getStatusClassName("Registred")}
                                >
                                  {t("Active")}
                                </span>
                              )}
                            </div>
                          </td>
                          <td className="h-px w-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              {/* <span className="text-sm text-gray-500">
                                {new Date(data.created_at).toLocaleString()}
                              </span> */}
                              {data.contact_phone_number}
                            </div>
                          </td>
                          <td className="h-px w-px whitespace-nowrap">
                            <div className="px-6 py-1.5">
                              <Link to={`/teams/${data.id}`} className="ml-3">
                                <div className="py-1 px-2 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white">
                                  <i className="bi bi-eye"></i>
                                  {t("View")}
                                </div>
                              </Link>

                              <div className="hs-dropdown relative inline-block [--placement:bottom-right] px-2">
                                <button
                                  id="hs-table-dropdown-6"
                                  type="button"
                                  className="hs-dropdown-toggle py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-md text-gray-700 align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                                >
                                  <i className="bi bi-three-dots"></i>
                                </button>
                                <div
                                  className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden  divide-y divide-gray-200 min-w-[10rem] z-10 bg-white shadow-2xl rounded-lg p-2 mt-2 dark:divide-gray-700 dark:bg-gray-800 dark:border dark:border-gray-700"
                                  aria-labelledby="hs-table-dropdown-6"
                                >
                                  {role === "super_admin" ||
                                  role === "developer" ? (
                                    <>
                                      <div className="py-2 first:pt-0 last:pb-0">
                                        <button
                                          className="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-red-600 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-red-500 dark:hover:bg-gray-700"
                                          onClick={() => deleteTeam(data.id)}
                                        >
                                          {t("Delete")}
                                        </button>
                                      </div>
                                      <div className="py-2 first:pt-0 last:pb-0">
                                        <button
                                          className="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                                          onClick={() => suspendTeam(data.id)}
                                        >
                                          {t("Suspend")}
                                        </button>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  <div className="py-2 first:pt-0 last:pb-0">
                                    <button
                                      className="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                                      onClick={() =>
                                        navigate(`/teams/activity/${data.id}`)
                                      }
                                    >
                                      {t("View activity")}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </>
                ) : (
                  <> </>
                )}
              </table>
              {/* End Table */}
              {/* Footer */}
              <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 dark:border-gray-700">
                <div>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    <span className="font-semibold text-gray-800 dark:text-gray-200">
                      {companyList?.page_info?.total_items}
                    </span>{" "}
                    {t("results")}
                  </p>
                </div>
                <div>
                  <div className="inline-flex gap-x-2">
                    <button
                      type="button"
                      className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                    >
                      <svg
                        className="w-3 h-3"
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                        />
                      </svg>
                      {t("Prev")}
                    </button>
                    <button
                      type="button"
                      className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                    >
                      {t("Next")}
                      <svg
                        className="w-3 h-3"
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              {/* End Footer */}
            </div>
          </div>
        </div>
      </div>
      {/* End Card */}
    </>
  );
}
