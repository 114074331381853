import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function ActivityUsertable({ data }: any) {
  // console.log(data, "report");
  const navigate = useNavigate();
  const { t } = useTranslation("score");

  return (
    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
      <thead className="bg-gray-50 dark:bg-slate-800">
        <tr>
          <th scope="col" className="pl-6 py-3 text-left">
            <label htmlFor="hs-at-with-checkboxes-main" className="flex">
              <input
                type="checkbox"
                className="shrink-0 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                id="hs-at-with-checkboxes-main"
              />
              <span className="sr-only">Checkbox</span>
            </label>
          </th>
          <th scope="col" className="pl-6 lg:pl-3 xl:pl-0 pr-6 py-3 text-left">
            <div className="flex items-center gap-x-2">
              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                {t("Name")}
              </span>
            </div>
          </th>
          <th scope="col" className="px-6 py-3 text-left">
            <div className="flex items-center gap-x-2">
              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                {t("Email")}
              </span>
            </div>
          </th>
          {/* <th scope="col" className="px-6 py-3 text-left">
        <div className="flex items-center gap-x-2">
          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
            Username
          </span>
        </div>
      </th> */}
          <th scope="col" className="px-6 py-3 text-left">
            <div className="flex items-center gap-x-2">
              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                {t("Searches Used")}
              </span>
            </div>
          </th>
          <th scope="col" className="px-6 py-3 text-left">
            <div className="flex items-center gap-x-2">
              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                {t("Searches Saved")}
              </span>
            </div>
          </th>
          {/* <th scope="col" className="px-6 py-3 text-left">
        <div className="flex items-center gap-x-2">
          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
            Last Login
          </span>
        </div>
      </th> */}
          {/* <th scope="col" className="px-6 py-3 text-left">
        <div className="flex items-center gap-x-2">
          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
            Date Created
          </span>
        </div>
      </th> */}
          <th scope="col" className="px-6 py-3 text-left">
            <div className="flex items-center gap-x-2">
              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                {t("Name")}
              </span>
            </div>
          </th>
          <th scope="col" className="px-6 py-3 ">
            <div className="flex items-center gap-x-2">
              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200">
                {t("Action")}
              </span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
        {data?.map((data: any) => (
          <tr key={data.user_id}>
            <td className="h-px w-px whitespace-nowrap">
              <div className="pl-6 py-3">
                <label htmlFor="hs-at-with-checkboxes-1" className="flex">
                  <input
                    type="checkbox"
                    className="shrink-0 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                    id="hs-at-with-checkboxes-1"
                  />
                  <span className="sr-only">Checkbox</span>
                </label>
              </div>
            </td>
            <td className="h-px w-px whitespace-nowrap">
              <div className="pl-6 lg:pl-3 xl:pl-0 pr-6 py-3">
                <div className="flex items-center gap-x-3">
                  <span className="inline-flex items-center justify-center h-[2.375rem] w-[2.375rem] rounded-full bg-blue-300 dark:bg-blue-700">
                    <span className="font-medium text-blue-800 leading-none dark:text-blue-200">
                      {data.displayName.charAt(0).toUpperCase()}
                    </span>
                  </span>
                  <div className="grow">
                    <span className="block text-sm font-semibold text-gray-800 dark:text-gray-200">
                      {data.displayName}
                    </span>
                    {/* <span className="block text-sm text-gray-500">
                  {data.displayName}
                </span> */}
                  </div>
                </div>
              </div>
            </td>
            <td className="h-px w-px whitespace-nowrap">
              <div className="px-6 py-2">
                <span className="text-sm text-gray-600 dark:text-gray-400">
                  {data?.mail}
                </span>
              </div>
            </td>
            <td className="h-px w-px whitespace-nowrap">
              <div className="px-6 py-3">
                <span className="block text-sm font-semibold text-gray-800 dark:text-gray-200">
                  {data?.used_search_credits}
                </span>
              </div>
            </td>
            <td className="h-px w-px whitespace-nowrap">
              <div className="px-6 py-3">
                <span className="block text-sm font-semibold text-gray-800 dark:text-gray-200">
                  {data.saved_search_results}
                </span>
                {/* <span className="inline-flex items-center gap-1.5 py-1 px-2 rounded-md text-xs font-medium bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-200">
              {data.department}
            </span> */}
              </div>
            </td>

            {/* <td className="h-px w-px whitespace-nowrap">
          <div className="px-6 py-3">
            {!data.is_active ? (
              <span className={getStatusClassName("Active")}>
                Active
              </span>
            ) : (
              <span className={getStatusClassName("Suspended")}>
                Suspended
              </span>
            )}
          </div>
        </td> */}
            {/* <td className="h-px w-px whitespace-nowrap">
          <div className="px-6 py-3">
            <span className="text-sm text-gray-500">login</span>
          </div>
        </td> */}
            {/* <td className="h-px w-px whitespace-nowrap">
          <div className="px-6 py-3">
            <span className="text-sm text-gray-500">2023</span>
          </div>
        </td> */}
            <td className="h-px w-px whitespace-nowrap">
              <div className="px-6 py-2">
                <span className="text-sm text-gray-600 dark:text-gray-400">
                  {data?.unsaved_search_results}
                </span>
              </div>
            </td>
            <td className="h-px w-px whitespace-nowrap">
              <div className="px-6 py-1.5">
                <div className="hs-dropdown relative inline-block [--placement:bottom-right] px-2">
                  <button
                    id="hs-table-dropdown-6"
                    type="button"
                    className="hs-dropdown-toggle py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-md text-gray-700 align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                  >
                    <i className="bi bi-three-dots"></i>
                  </button>
                  <div
                    className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden  divide-y divide-gray-200 min-w-[10rem] z-10 bg-white shadow-2xl rounded-lg p-2 mt-2 dark:divide-gray-700 dark:bg-gray-800 dark:border dark:border-gray-700"
                    aria-labelledby="hs-table-dropdown-6"
                  >
                    <div className="py-2 first:pt-0 last:pb-0">
                      <button
                        onClick={() =>
                          navigate(`/teams/activity/searches/${data?.user_id}`)
                        }
                        className="flex items-center gap-x-3 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                      >
                        {t("View user's searches")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
