import { useContext } from "react";
import { AuthContext } from "../context/AuthProvider";
import { useState, useEffect } from "react";
import axios from "axios";

const useAuth = () => {
  return useContext(AuthContext);
};

export default useAuth;

// @ts-nocheck

export function useTranslateNested(data, targetLang) {
  const [translatedData, setTranslatedData] = useState(data);


  useEffect(() => {
    const translateText = async (text) => {
      try {
        const response = await axios.post(
          "https://translation.googleapis.com/language/translate/v2",
          {},
          {
            params: {
              q: text,
              target: targetLang,
              key: "AIzaSyA9e3Xnl0AAZfWfKaXAM9Vh0lWqA_a_7lo",
            },
          }
        );
        return response.data.data.translations[0].translatedText;
      } catch (error) {
        console.error("Translation error:", error);
        return text; // Fallback to original text
      }
    };

    const translateNestedObject = async (obj, isTopLevel = true) => {
      if (typeof obj !== "object" || obj === null) {
        return obj;
      }

      if (Array.isArray(obj)) {
        return Promise.all(
          obj.map((item) => translateNestedObject(item, false))
        );
      }

      const translatedObj = {};
      for (const [key, value] of Object.entries(obj)) {
        const translatedKey = isTopLevel ? await translateText(key) : key;
        if (typeof value === "string") {
          translatedObj[translatedKey] = await translateText(value);
        } else {
          translatedObj[translatedKey] = await translateNestedObject(
            value,
            false
          );
        }
      }
      return translatedObj;
    };

    translateNestedObject(data).then(setTranslatedData);
  }, [data, targetLang]);

  return translatedData;
}
