//@ts-nocheck
import { useState, useEffect } from "react";
import ChatLogItem from "./chat/ChatLogItem";
import TypingAnimation from "./chat/TypingAnimation";
import { ChatCompletion } from "../data/chatData";
import toast from "react-hot-toast";
import { axiosPrivateOid } from "../api/axiosPrivate";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useRiskData } from "../context/RIskData"; // Import your custom hook
import { useRiskDatas } from "../context/LivestockRiskData";
import data from "../json/livestockData.json";
import cropData from "../json/cropData.json";
import stringSimilarity from "string-similarity";
// import ReactMarkdown from "react-markdown";

interface ChatMessage {
  type: "user" | "bot";
  message: string;
}

const ChatBot = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [chatLog, setChatLog] = useState<ChatMessage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const { t } = useTranslation("score");
  const { riskData } = useRiskData();
  const { topThreeIndicis, topThreeRisks, profitData } = useRiskDatas();
  const [conversationContext, setConversationContext] = useState<{
    location?: string;
    crop?: string;
    livestockType?: string;
    language?: string;
  }>({});

  const [conversationHistory, setConversationHistory] = useState<
    { role: string; content: string }[]
  >([]);
  const currentRiskData = conversationContext.riskData;
  const currentProfitData = conversationContext.profitData;
  useEffect(() => {
    if (conversationContext.riskData) {
      const { riskData, location } = conversationContext;
      callOpenAI(
        `User asked about crops. Location: ${location}. Use this to provide a detailed response.`
      );
    }

    if (conversationContext.profitData) {
      const { profitData, location } = conversationContext;
      callOpenAILivestock(
        `User asked about livestock. Location: ${location}. Use this to provide a detailed response.`
      );
    }
  }, [conversationContext]); 

  const isRiskDataChanged =
    JSON.stringify(currentRiskData) !== JSON.stringify(riskData);
  const isProfitDataChanged =
    JSON.stringify(currentProfitData) !== JSON.stringify(profitData);

  const toggleChat = () => {
    setIsChatOpen((prev) => !prev);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (inputValue.trim() === "") {
      toast.error("Please enter a message");
      return;
    }

    setChatLog((prevChatLog) => [
      ...prevChatLog,
      { type: "user", message: inputValue },
    ]);

    sendMessage(inputValue);

    setInputValue("");
  };

  const sendMessage = (message: string) => {
    const cropSynonymMap: Record<string, Record<string, string[]>> = {
      adaptations: {
        en: [
          "adaptation",
          "adjustments",
          "changes",
          "modifications",
          "alterations",
          "improve",
          "productivity",
          "improvements",
          "adjust",
          "modify",
          "adapt",
          "change",
        ],
        es: [
          "adaptación",
          "ajustes",
          "cambios",
          "modificaciones",
          "alteraciones",
          "mejorar",
          "productividad",
          "mejoras",
          "ajustar",
          "modificar",
          "cambiar",
        ],
      },
      location_name: {
        en: [
          "location",
          "place",
          "where",
          "shops",
          "stores",
          "area",
          "region",
          "here",
          "zone",
          "areas",
        ],
        es: [
          "lugar",
          "ubicación",
          "dónde",
          "tiendas",
          "almacenes",
          "área",
          "región",
          "aquí",
          "zona",
        ],
      },
      climate_scores: {
        en: [
          "climate",
          "weather",
          "conditions",
          "temperature",
          "forecast",
          "drought",
          "precipitation",
          "elevation",
        ],
        es: [
          "clima",
          "tiempo",
          "condiciones",
          "temperatura",
          "pronóstico",
          "sequía",
          "precipitación",
          "elevación",
        ],
      },
      crop: {
        en: ["plants", "seeds", "agriculture", "crops", "harvest"],
        es: ["plantas", "semillas", "agricultura", "cultivos", "cosecha"],
      },
      soil_scores: {
        en: ["soil", "ground", "earth", "terrain", "soil risk", "ph"],
        es: ["suelo", "tierra", "terreno", "riesgo del suelo", "ph"],
      },
      water_scores: {
        en: ["water", "rainfall", "erosivity"],
        es: ["agua", "lluvia", "erosividad"],
      },
      composite_total_risk: {
        en: ["composite", "total risk"],
        es: ["compuesto", "riesgo total"],
      },
      composite_total_score: {
        en: ["composite", "total score"],
        es: ["compuesto", "puntuación total"],
      },
    };

    const livestockSynonymMap: Record<string, Record<string, string[]>> = {
      livestock_type: {
        en: ["livestock", "animal", "animals", "cattle", "sheep", "rear"],
        es: ["ganado", "animal", "animales", "vacas", "ovejas", "criar"],
      },
      location_name: {
        en: ["location", "place", "where", "farm", "pasture", "region"],
        de: ["Ort", "Platz", "wo", "Bauernhof", "Weide", "Region"],
        es: ["ubicación", "lugar", "dónde", "granja", "pradera", "región"],
      },
      climate_scores: {
        en: [
          "climate",
          "risk",
          "weather",
          "conditions",
          "temperature",
          "forecast",
          "drought",
          "elevation",
          "precipitation",
          "livestock",
          "animal",
          "animals",
          "cattle",
          "sheep",
          "rear",
          "breed",
        ],
        es: [
          "clima",
          "riesgo",
          "clima",
          "condiciones",
          "temperatura",
          "pronóstico",
          "sequía",
          "altitud",
          "precipitación",
          "ganado",
          "animal",
          "animales",
          "vacas",
          "ovejas",
          "criar",
          "raza",
        ],
      },
      profitability_scores: {
        en: ["profitability", "income", "revenue"],
        es: ["rentabilidad", "ingresos", "recaudación"],
      },
      water_scores: {
        en: ["water", "ground", "groundwater"],
        es: ["agua", "suelo", "agua subterránea"],
      },
      adaptations: {
        en: [
          "adaptation",
          "adjustments",
          "changes",
          "modifications",
          "alterations",
          "improve",
          "productivity",
          "improvements",
          "adjust",
          "modify",
          "adapt",
          "change",
        ],
        es: [
          "adaptación",
          "ajustes",
          "cambios",
          "modificaciones",
          "alteraciones",
          "mejorar",
          "productividad",
          "mejoras",
          "ajustar",
          "modificar",
          "adaptar",
          "cambiar",
        ],
      },
    };

    const handleComputationQuery = () => {
      // List of computation-related keywords (e.g., "calculate", "compute")
      const computationKeywords = {
        en: [
          "compute",
          "calculate",
          "formula",
          "determine",
          "derive",
          "computation",
        ],
        es: [
          "computar",
          "calcular",
          "calcula",
          "fórmula",
          "determinar",
          "derivar",
          "computación",
        ],
      };
      // Translations of "how" in different languages
      const howKeywords = {
        en: ["how"],
        de: ["wie"],
        es: ["cómo"],
        fr: ["comment"],
        hi: ["कैसे"],
        id: ["bagaimana"],
        ja: ["どうやって", "どのように"],
        pt: ["como"],
        ru: ["как"],
        tl: ["paano"],
        zh: ["如何", "怎么", "怎样"],
      };

      function containsHow(message: string): boolean {
        const allHowWords = Object.values(howKeywords).flat();
        const normalizedMessage = message.toLowerCase();
        const containsHowWord = allHowWords.some((word) =>
          normalizedMessage.includes(word.toLowerCase())
        );
        return containsHowWord;
      }

      // Function to check if the message is a computation-related query
      function isComputationQuery(message: string): boolean {
        if (!containsHow(message)) return false;
        const allComputationKeywords =
          Object.values(computationKeywords).flat();
        const normalizedMessage = message.toLowerCase();

        const hasComputationKeyword = allComputationKeywords.some((keyword) => {
          const similarity = stringSimilarity.compareTwoStrings(
            normalizedMessage,
            keyword.toLowerCase()
          );
          return similarity > 0.3; // Adjust the similarity threshold as needed
        });
        return hasComputationKeyword;
      }
      if (isComputationQuery(message)) {
        const messageLower = message.toLowerCase();

        // List of relevant climate-related topics
        const climateKeywords = {
          en: [
            "drought",
            "precipitation",
            "temperature",
            "climate",
            "soil",
            "water",
            "fertility",
            "nutrient",
            "nutrients",
            "erosion",
            "groundwater",
            "erosivity",
            "stress",
          ],
          es: [
            "sequía",
            "precipitación",
            "temperatura",
            "clima",
            "suelo",
            "agua",
            "fertilidad",
            "nutriente",
            "nutrientes",
            "erosión",
            "agua subterránea",
            "erosividad",
            "estrés",
          ],
        };

        const livestockKeywords = {
          en: [
            "climate",
            "water",
            "profitability",
            "temperature",
            "drought",
            "risk",
            "groundwater",
            "humidity",
          ],

          es: [
            "clima",
            "climatico",
            "agua",
            "rentabilidad",
            "temperatura",
            "sequía",
            "riesgo",
            "agua subterránea",
            "humedad",
          ],
        };

        let summary = "";
        const isKeywordSimilar = Object.values(climateKeywords)
          .flat()
          .some((keyword) => {
            const similarity = stringSimilarity.compareTwoStrings(
              message.toLowerCase(),
              keyword.toLowerCase()
            );
            return similarity > 0.2;
          });
        const isKeywordLivestockSimilar = Object.values(livestockKeywords)
          .flat()
          .some((keyword) => {
            const similarity = stringSimilarity.compareTwoStrings(
              message.toLowerCase(),
              keyword.toLowerCase()
            );
            return similarity > 0.2;
          });
        if (isKeywordSimilar) {
          if (riskData) {
            for (const language in climateKeywords) {
              for (const keyword of climateKeywords[language]) {
                if (messageLower.includes(keyword)) {
                  let summaryKey = "";

                  if (["drought", "sequía"].includes(keyword)) {
                    summaryKey = "drought";
                  } else if (
                    ["precipitation", "precipitación"].includes(keyword)
                  ) {
                    summaryKey = "precipitation";
                  } else if (["temperature", "temperatura"].includes(keyword)) {
                    summaryKey = "temperature";
                  } else if (
                    [
                      "soil",
                      "fertility",
                      "nutrient",
                      "nutrients",
                      "suelo",
                      "fertilidad",
                      "nutriente",
                      "nutrientes",
                      "erosión",
                    ].includes(keyword)
                  ) {
                    summaryKey = "soil";
                  } else if (
                    [
                      "water",
                      "erosion",
                      "groundwater",
                      "erosivity",
                      "stress",
                      "erosión",
                      "aguasubterránea",
                      "erosividad",
                      "estrés",
                      "agua",
                    ].includes(keyword)
                  ) {
                    summaryKey = "water";
                  } else if (["climate", "clima"].includes(keyword)) {
                    summaryKey = "climate";
                  }

                  // Stop further processing once we find a valid match
                  if (summaryKey && cropData.data[summaryKey]) {
                    const detectedSummary =
                      cropData.data[summaryKey][language] ||
                      cropData.data[summaryKey]["en"]; // Default to English if not found

                    setChatLog((prevChatLog) => [
                      ...prevChatLog,
                      { type: "bot", message: detectedSummary },
                    ]);
                    return true; // Exit after finding the first valid keyword match
                  }
                }
              }
            }
          }
        }
        if (isKeywordLivestockSimilar) {
          if (profitData) {
            for (const language in livestockKeywords) {
              for (const keyword of livestockKeywords[language]) {
                if (messageLower.includes(keyword)) {
                  let summaryKey = "";
                  if (
                    ["climate", "clima", "climatico", "sequía"].includes(
                      keyword
                    )
                  ) {
                    summaryKey = "climate";
                  } else if (
                    ["water", "agua", "agua subterránea"].includes(keyword)
                  ) {
                    summaryKey = "water";
                  } else if (
                    ["temperature", "temperatura", "humedad"].includes(keyword)
                  ) {
                    summaryKey = "temperature";
                  } else if (
                    ["profitability", "rentabilidad"].includes(keyword)
                  ) {
                    summaryKey = "profitability";
                  }
                  // If a summary was found, send it as a response and stop further checks
                  if (summaryKey && data.data[summaryKey]) {
                    const detectedSummary =
                      data.data[summaryKey][language] ||
                      data.data[summaryKey]["en"]; // Default to English if not found
                    setChatLog((prevChatLog) => [
                      ...prevChatLog,
                      { type: "bot", message: detectedSummary },
                    ]);
                    return true; // Stop processing after sending the summary
                  }
                }
              }
            }
          }
        }

        callOpenAI(
          `User asked: "${message}". I couldn't find specific details on the calculation for the requested score. Please clarify what you're asking about.`
        );
        return true; // Stop processing after identifying it's a computation-related query
      }
      return false;
    };

    if (handleComputationQuery()) return;
    // Function to detect the language of the query
    const detectLanguage = (query: string): string => {
      const fields = Object.keys(cropSynonymMap);

      for (let field of fields) {
        if (
          cropSynonymMap[field].es &&
          cropSynonymMap[field].es.some((word) =>
            query.toLowerCase().includes(word)
          )
        ) {
          return "es"; // Spanish detected
        }
      }
      const fieldsLivestock = Object.keys(livestockSynonymMap);

      for (let field of fieldsLivestock) {
        if (
          livestockSynonymMap[field].es &&
          livestockSynonymMap[field].es.some((word) =>
            query.toLowerCase().includes(word)
          )
        ) {
          return "es"; // Spanish detected
        }
      }
      if (/[\u0400-\u04FF]/.test(query)) return "ru"; // Cyrillic
      if (/[\u4E00-\u9FFF]/.test(query)) return "zh"; // Chinese
      if (/[\u3040-\u309F\u30A0-\u30FF]/.test(query)) return "ja"; // Japanese
      if (/[\u0E00-\u0E7F]/.test(query)) return "th"; // Thai
      if (/[\u0600-\u06FF]/.test(query)) return "ar"; // Arabic
      if (/[\u0900-\u097F]/.test(query)) return "hi"; // Hindi
      if (/[áéíóúñ]/.test(query)) return "es"; // Spanish often uses accented characters or ñ
      if (/[a-zA-Z]/.test(query)) {
        return "en"; // English detected
      }
    };

    const language = detectLanguage(message);
    // Update the conversation context with the detected language (if not already set)
    if (!conversationContext.language) {
      setConversationContext((prevContext) => ({
        ...prevContext,
        language,
      }));
    }

    // Add the user's message to the conversation history
    setConversationHistory((prevHistory) => [
      ...prevHistory,
      { role: "user", content: message },
    ]);
    // Function to check if a query matches any of the synonyms in the provided map
    const isRelevantQuery = (
      query: string,
      synonymMap: Record<string, string[]>,
      language: string
    ) => {
      const normalizedQuery = query.toLowerCase();
      const synonyms = synonymMap[language] || []; // Ensure synonyms is always an array
      // Use fuzzy matching to check if any synonym is similar to the query
      const matches = stringSimilarity.findBestMatch(normalizedQuery, synonyms);
      const bestMatch = matches.bestMatch;

      // Set a similarity threshold (e.g., 0.7)
      const similarityThreshold = 0.2;
      return bestMatch.rating >= similarityThreshold;
    };
    const isCropQuery = (
      query: string,
      riskData: any,
      language: string
    ): boolean => {
      if (!riskData) {
        return false;
      }
      // Check all keys in cropSynonymMap for relevance
      for (const key in cropSynonymMap) {
        const synonymMap = cropSynonymMap[key];
        if (isRelevantQuery(query, synonymMap, language)) {
          return true;
        }
      }
      return false;
    };
    const isLivestockQuery = (
      query: string,
      profitData: any,
      language: string
    ): boolean => {
      if (!profitData) {
        return false;
      }
      // Check all keys in livestocksynonymmap for relevance
      for (const key in livestockSynonymMap) {
        const synonymMap = livestockSynonymMap[key];
        if (isRelevantQuery(query, synonymMap, language)) {
          return true;
        }
      }
      return false;
    };
    const matchQueryToKey = (
      query: string,
      obj: Record<string, any>,
      synonymMap: Record<string, string[]>,
      language: string
    ): string | null => {
      const normalizedQuery = query.toLowerCase();

      // First, check for synonyms using fuzzy matching
      for (const [key, synonyms] of Object.entries(synonymMap)) {
        const languageSynonyms = synonyms[language] || []; // Ensure synonyms is always an array

        // Use fuzzy matching to find the best match
        const matches = stringSimilarity.findBestMatch(
          normalizedQuery,
          languageSynonyms
        );
        const bestMatch = matches.bestMatch;

        // Set a similarity threshold (e.g., 0.7)
        const similarityThreshold = 0.2;
        if (bestMatch.rating >= similarityThreshold) {
          return key;
        }
      }

      // Then, check for direct matches using fuzzy matching
      const directMatches = stringSimilarity.findBestMatch(
        normalizedQuery,
        Object.keys(obj)
      );
      const bestDirectMatch = directMatches.bestMatch;

      if (bestDirectMatch.rating >= similarityThreshold) {
        return bestDirectMatch.target;
      }

      return null; // Return null if no match is found
    };

    // Function to extract relevant data from the object
    const extractRelevantData = (
      query: string,
      obj: Record<string, any>,
      synonymMap: Record<string, string[]>,
      language: string
    ) => {
      const matchedKey = matchQueryToKey(query, obj, synonymMap, language);

      if (matchedKey && matchedKey in obj) {
        const value = obj[matchedKey];
        const formattedValue =
          typeof value === "object"
            ? JSON.stringify(value, null, 2)
            : Array.isArray(value)
            ? value.join(", ")
            : value.toString();
        // console.log(matchedKey)
        // console.log(formattedValue)
        return { keyword: matchedKey, context: formattedValue };
      }
      return null;
    };
    // Check for location context in crop data
    const checkForCropsAndLocation = (
      query: string,
      obj: Record<string, any>,
      language: string
    ) => {
      if (obj.location_name) {
        return `This query is related to crops. Crop found: ${obj.crop}. Location found: ${obj.location_name}.`;
      }
      return `This query seems to be related to crops, but no location was provided. Please include a location to proceed.`;
    };
    // Check for location context in livestock data
    const checkForLivestockAndLocation = (
      query: string,
      obj: Record<string, any>,
      language: string
    ) => {
      if (obj.location_name) {
        return `This query is related to livestock ${obj.livestock_type}. Location found: ${obj.location_name} for this object ${obj}.`;
      }
      return `This query seems to be related to livestock, but no location was provided. Please include a location to proceed.`;
    };
    const handleQuery = (message: string, riskData: any, profitData: any) => {
      const language = conversationContext.language || detectLanguage(message);

      // If either riskData or profitData has changed, clear the context
      if (isRiskDataChanged || isProfitDataChanged) {
        setConversationContext((prevContext) => ({
          ...prevContext,
          riskData: null,
          profitData: null,
          location: null,
          crop: null,
          livestockType: null,
        }));
      }
      if (riskData || profitData) {
        if (riskData && isCropQuery(message, riskData, language)) {
          const extracted = extractRelevantData(
            message,
            riskData,
            cropSynonymMap,
            language
          );
          const locationContext = checkForCropsAndLocation(
            message,
            riskData,
            language
          );
          if (extracted) {
            const { keyword, context } = extracted;

            // Update conversation context with new crop data
            setConversationContext((prevContext) => {
              const newContext = {
                ...prevContext,
                riskData: riskData, 
                location: riskData.location_name, 
                crop: riskData.crop, 
              };
              // callOpenAI(
              //   `User asked: "${message}". Relevant data found: '${keyword}' with value: ${context} and Location found: ${newContext.location}. Use this to provide a detailed response.`
              // );

              return newContext;
            });
            return true;
          }
        }
        if (profitData && isLivestockQuery(message, profitData, language)) {
          const extracted = extractRelevantData(
            message,
            profitData.total_scores,
            livestockSynonymMap,
            language
          );
          const locationContext = checkForLivestockAndLocation(
            message,
            profitData.total_scores,
            language
          );
          if (extracted) {
            const { keyword, context } = extracted;

            // Update conversation context with new livestock data
            setConversationContext((prevContext) => {
              const newContext = {
                ...prevContext,
                profitData: profitData.total_scores,
                location: profitData.total_scores.location_name,
                livestockType: profitData.total_scores.livestock_type,
              };
              // Directly use the updated values in the call to OpenAI
              // callOpenAILivestock(
              //   `User asked: "${message}". Relevant data found: '${keyword}' with value: ${context} and ${newContext.location}. Use this to provide a detailed response.`
              // );
              return newContext;
            });

            return true;
          }
        }
      } else {
        // callOpenAI(
        //   `User asked: "${message}". Use this to provide a detailed response.`
        // );
      }
    };
    // Handle the query and check if it was processed
    const isQueryHandled = handleQuery(message, riskData, profitData);
    // If the query was not handled by handleQuery, pass it to callOpenAI
    if (!isQueryHandled) {
      if (riskData) {
        callOpenAI(message);
      } else if (profitData) {
        callOpenAILivestock(message);
      }
    }
  };

  /**
   * Call OpenAI API to process the response.
   */
  const removeMarkdown = (text: string): string => {
    // Remove Markdown characters like #, *, `, etc.
    return text
      .replace(/#/g, "") // Remove headings
      .replace(/\*/g, "") // Remove bold/italic
      .replace(/`/g, "") // Remove code blocks
      .replace(/_/g, "") // Remove underscores
      .replace(/\[(.*?)\]\(.*?\)/g, "$1"); // Remove links but keep the text
  };
  const callOpenAI = (
    message: string,
    location: string,
    crop: string,
    context: string
  ) => {
    // const currentLanguage = conversationContext.language || "en";
    const URL = "/v1/openai/chat";
    if (isRiskDataChanged) {
      const data = {
        messages: [
          ...conversationHistory, // Include the conversation history
          {
            role: "user",
            content: `Context: Location - ${location}, Crop - ${crop} using ${context}. User asked: "${message}".`,
            // content: `User asked: "${message}".`,
          },
        ],
      };
    }
    const data = {
      messages: [
        ...conversationHistory, // Include the conversation history
        {
          role: "user",
          content: `Context: Location - ${conversationContext.location}, Crop - ${conversationContext.crop} using ${context}. User asked: "${message}".`,
          // content: `User asked: "${message}".`,
        },
      ],
    };

    setIsLoading(true);

    axiosPrivateOid<ChatCompletion>({
      method: "POST",
      url: URL,
      data: data,
    })
      .then((response) => {
        let botMessage = response.data;
        const botResponse = removeMarkdown(botMessage);

        setChatLog((prevChatLog) => [
          ...prevChatLog,
          {
            type: "bot",
            message: botResponse || "I'm sorry, I didn't understand that.",
          },
        ]);
        // Add the bot's response to the conversation history
        setConversationHistory((prevHistory) => [
          ...prevHistory,
          { role: "assistant", content: botResponse },
        ]);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error("OpenAI API error:", error);

        setChatLog((prevChatLog) => [
          ...prevChatLog,
          {
            type: "bot",
            message:
              "Sorry, I couldn't process your request. Please try again later.",
          },
        ]);

        setIsLoading(false);
      });
  };
  const callOpenAILivestock = (
    message: string,
    location: string,
    livestock_type: string,
    context: string
  ) => {
    // const currentLanguage = conversationContext.language || "en";

    const URL = "/v1/openai/chat";
    if (isProfitDataChanged) {
      const data = {
        messages: [
          ...conversationHistory, 
          {
            role: "user",
            // content: `Context: Location - ${location}, Livestock - ${livestock_type} using ${context}. User asked: "${message}". Respond in ${currentLanguage}`,
            content: `Context: Location - ${location}, Livestock - ${livestock_type} using ${context}. User asked: "${message}".`,
            // content: `User asked: "${message}".`,
          },
        ],
      };
    }
    const data = {
      messages: [
        ...conversationHistory,
        {
          role: "user",
          content: `Context: Location - ${conversationContext.location}, Livestock - ${conversationContext.livestock_type} using ${context}. User asked: "${message}.`,
          // content: `User asked: "${message}".`,
        },
      ],
    };

    setIsLoading(true);

    axiosPrivateOid<ChatCompletion>({
      method: "POST",
      url: URL,
      data: data,
    })
      .then((response) => {
        let botMessage = response.data;
        const botResponse = removeMarkdown(botMessage);

        setChatLog((prevChatLog) => [
          ...prevChatLog,
          {
            type: "bot",
            message: botResponse || "I'm sorry, I didn't understand that.",
            // message:
            //   <BotMessage message={botMessage} /> ||
            //   "I'm sorry, I didn't understand that.",
          },
        ]);
        // Add the bot's response to the conversation history
        setConversationHistory((prevHistory) => [
          ...prevHistory,
          { role: "assistant", content: botResponse },
        ]);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error("OpenAI API error:", error);

        setChatLog((prevChatLog) => [
          ...prevChatLog,
          {
            type: "bot",
            message:
              "Sorry, I couldn't process your request. Please try again later.",
          },
        ]);

        setIsLoading(false);
      });
  };

  return (
    <>
      {/* component */}
      <div>
        <button
          id="chatbot"
          className={` w-14	 inline-flex items-center justify-center text-sm font-medium border  rounded-full bg-green-600 hover:bg-gray-700 m-0 cursor-pointer border-gray-200 bg-none p-0 normal-case leading-5 hover:text-gray-900 ${
            isChatOpen ? "chat-open" : "chat-closed"
          } bot-container`}
          type="button"
          aria-haspopup="dialog"
          aria-expanded={isChatOpen}
          onClick={toggleChat}
        >
          <img
            src="/img/BotLogo.png"
            alt=""
            className="object-cover	overflow-hidden	rounded-full bot-animation"
          />
          <div class="bot-text">Agribot</div>
        </button>
        {isChatOpen && (
          <div
            id="hs-chatbot-container"
            className={`fixed bottom-[calc(4rem+1.5rem)] right-0 mr-4 bg-white  rounded-lg border border-[#e5e7eb] w-[80%] md:w-[440px] h-[560px] z-50 ${
              isChatOpen ? "chat-open" : "chat-closed"
            }`}
          >
            {/* Heading */}
            <div className="flex justify-between items-center space-y-1.5 p-6 bg-slate-700  border-b">
              <div className="">
                <h2 className="font-semibold text-white text-lg tracking-tight">
                  AgriBot
                </h2>
                <p className="text-sm text-gray-300 leading-3 mt-2">
                  {t("Your climate-smart agriculture guide")}
                </p>
              </div>
              <button
                type="button"
                onClick={toggleChat}
                className="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-white hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all  dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
                data-hs-overlay="#hs-focus-management-modal"
              >
                <span className="sr-only">Close</span>
                <i className="bi bi-x-lg text-xl"></i>
              </button>
            </div>
            <div id="hs-message-container" className="px-6 pb-6">
              {/* Chat Container */}
              <div
                id="chat-container"
                className="pr-4 h-[400px]"
                style={{
                  minWidth: "100%",
                  display: "table",
                  overflowY: "scroll",
                }}
              >
                <div className="flex gap-3 my-4 text-gray-600 text-sm flex-1">
                  <span className="flex-shrink-0 inline-flex items-center justify-center h-[2.375rem] w-[2.375rem] rounded-full bg-blue-600">
                    <span className="text-sm font-medium text-white leading-none">
                      <img
                        src="/img/BotLogo.png"
                        alt=""
                        className="object-cover	overflow-hidden	rounded-full"
                      />
                    </span>
                  </span>

                  <p className="leading-relaxed">
                    <span className="block font-bold text-gray-700">
                      AgriBot{" "}
                    </span>
                    <p className="text-sm">
                      {t("Welcome to Adapta CS. How can we help you?")}
                    </p>
                  </p>
                </div>
                {chatLog.map((message, index) => (
                  <ChatLogItem
                    key={index}
                    type={message.type}
                    message={message.message}
                  />
                ))}
                {isLoading && (
                  <div key={chatLog.length} className="flex justify-start">
                    <div className="bg-gray-200 rounded-lg p-4 text-white max-w-sm">
                      <TypingAnimation />
                    </div>
                  </div>
                )}
              </div>
              {/* Input box  */}
              <div className="flex items-center pt-0">
                <form
                  className="flex items-center justify-center w-full space-x-2"
                  onSubmit={handleSubmit}
                >
                  <input
                    className="flex h-10 w-full rounded-md border border-[#e5e7eb] px-3 py-2 text-sm placeholder-[#6b7280] focus:outline-none focus:ring-2 focus:ring-[#9ca3af] disabled:cursor-not-allowed disabled:opacity-50 text-[#030712] focus-visible:ring-offset-2"
                    placeholder={t("Type your message")}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                  <button
                    className="inline-flex items-center justify-center rounded-md text-sm font-medium text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 bg-slate-700 hover:bg-[#111827E6] h-10 px-4 py-2"
                    type="submit"
                  >
                    <i className="bi bi-send"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ChatBot;
