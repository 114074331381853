//@ts-nocheck
import Swal from "sweetalert2";
//@ts-ignore
import { ChevronRight, EditIcon } from "lucide-react";
import editIcon from "/edit.svg";
import { axiosEco } from "../../api/axiosPrivate";
import BreadHeader from "../../components/breadheader";
import MainDashboard from "../../components/dashboards/main_dashboard";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

const validationSchema = yup.object({
  crop_name: yup.string().required("Debtor name is required"),
  optimal_min_temperature: yup
    .string()
    .required("Debtor contacts are required"),
  optimal_max_temperature: yup
    .string()
    .required("Reviewer comments are required"),
  optimal_min_rainfall: yup.string(),

  optimal_max_rainfall: yup.string(),
  absolute_min_temperature: yup.string(),
  absolute_max_temperature: yup.string(),
  absolute_min_rainfall: yup.string(),
  absolute_max_rainfall: yup.string(),
  optimal_max_soil_ph: yup.string(),
  optimal_min_soil_ph: yup.string(),
  absolute_max_soil_ph: yup.string(),
  absolute_min_soil_ph: yup.string(),
  absolute_max_aridity: yup.string(),
  absolute_min_aridity: yup.string(),
  optimal_max_aridity: yup.string(),
  optimal_min_aridity: yup.string(),
  altitude_min: yup.string(),
  altitude_max: yup.string(),
  life_cycle: yup.string(),
});

export default function EcoCrop() {
  const [cropList, setCrop] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [selectedCrop, setSelectedCrop] = useState(null);
  const [cropData, setCropData] = useState({});
  const [editedCropData, setEditedCropData] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation("home");
  const { register, handleSubmit } = useForm<any>({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resolver: yupResolver(validationSchema) as any,
  });
  const URL_LIST = `v1/eco-crop?page=${page}&per_page=15`;
  // const loadCrops = () => {
  //   axiosPrivate<ecropData>({ method: "GET", url: URL_LIST })
  //     .then((data) => {
  //       setCrop(data);
  //     })
  //     .catch((error) => {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Not Authorized.",
  //         text: "You are not Authorized to access this Section",
  //       });
  //       console.error("API Error:", error);
  //     });
  // };

  async function fetchCrops() {
    axiosEco<any>({ method: "GET", url: URL_LIST })
      .then((data) => {
        setCrop(data);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: t("Oops..."),
          text: t("Something went wrong!"),
        });
        console.error("API Error:", error);
      });
  }
  useEffect(() => {
    fetchCrops();
  }, [page]);
  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    Swal.fire({
      title: t("Are you sure?"),
      text: t("You won’t be able to revert this!"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yes, proceed!"),
      cancelButtonText: t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        // Action to proceed further if user clicks "OK"
        try {
          axiosEco<any>({
            method: "PUT",
            url: `/v1/eco-crop/${selectedCrop}/update`,
            data: editedCropData,
          })
            .then(() => {
              Swal.fire({
                icon: "success",

                text: t("Values edited successfully."),
              });
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: t("Oops..."),
                text: t("Something went wrong!"),
              });
              console.error("API Error:", error);
            });

          setIsDialogOpen(false);
        } catch (err) {
          setError("Error updating crop data: " + err.message);
        } finally {
          setIsLoading(false);
        }
      } else {
        console.log("User canceled");
        return;
      }
    });
  };
  async function deleteCrop(id: any) {
    // const name = id.charAt(0).toUpperCase() + id.slice(1);
    // const name = id.charAt(0).toUpperCase() + id.slice(1);
    axiosEco<any>({ method: "DELETE", url: `/v1/eco-crop/${id}` })
      .then(() => {
        fetchCrops();
        Swal.fire({
          icon: "success",
          title: "",
          text: t("Successfully deleted"),
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: t("Oops..."),
          text: t("Something went wrong!"),
        });
        console.error("API Error:", error);
      });
  }

  async function onSubmit(data: any) {
    axiosEco<any>({ method: "POST", url: `/v1/eco-crop/new`, data })
      .then(() => {
        fetchCrops();
        Swal.fire({
          icon: "success",
          title: "",
          text: t("Successfully Added Crop"),
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: t("Oops..."),
          text: t("Something went wrong!"),
        });
        console.error("API Error:", error);
      });
  }
  function handlePrev() {
    if (page > 1) {
      setPage((s) => s - 1);
    }
  }
  async function fetchCropData(crop) {
    setIsLoading(true);
    setError(null);
    try {
      axiosEco<any>({ method: "GET", url: `/v1/eco-crop/${crop}` })
        .then((data) => {
          const { crop_name, ...numericData } = data.data;
          setCropData(numericData);
          setEditedCropData(numericData);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: t("Oops..."),
            text: t("Something went wrong!"),
          });
          console.error("API Error:", error);
        });
    } catch (err) {
      setError("Error fetching crop data: " + err.message);
    } finally {
      setIsLoading(false);
    }
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedCropData((prevData) => ({
      ...prevData,
      [name]: value, // This should update the correct field in the editedCropData
    }));
  };
  const handleCropSelect = (crop) => {
    setSelectedCrop(crop);
    fetchCropData(crop);
    setIsDialogOpen(true);
  };
  return (
    <MainDashboard>
      <div>
        <BreadHeader
          title={t("Eco Crops")}
          description={t("Add, Edit and Search Crops")}
        />
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="grid md:grid-cols-2 gap-12">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="border rounded-lg overflow-hidden dark:border-gray-700">
                  <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                    <thead className="bg-gray-50 dark:bg-gray-700">
                      <tr>
                        <th scope="col" className="py-3 ps-4">
                          <div className="flex items-center h-5">
                            <input
                              id="hs-table-checkbox-all"
                              type="checkbox"
                              className="border-[#338FD9] rounded text-blue-600 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                            />
                            <label
                              htmlFor="hs-table-checkbox-all"
                              className="sr-only"
                            >
                              Checkbox
                            </label>
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                        >
                          {t("No.")}
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                        >
                          {t("Name")}
                        </th>

                        <th
                          scope="col"
                          className="px-6 py-3 text-end text-xs font-medium text-gray-500 uppercase"
                        >
                          {t("Action")}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                      {cropList?.data?.data.map((crop, key) => (
                        <tr>
                          <td className="py-3 ps-4">
                            <div className="flex items-center h-5">
                              <input
                                id="hs-table-checkbox-1"
                                type="checkbox"
                                className="border-[#338FD9] rounded text-blue-600 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                              />
                              <label
                                htmlFor="hs-table-checkbox-1"
                                className="sr-only"
                              >
                                Checkbox
                              </label>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
                            {key + 1}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
                            {crop}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
                            {/* <button
                              onClick={() => deleteCrop(crop)}
                              type="button"
                              className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                            >
                              Delete
                            </button> */}
                            <div className="flex align-middle justify-around cursor-pointer">
                              <div onClick={() => deleteCrop(crop)}>
                                <img src="/delete.svg" alt="" />
                              </div>
                              <div onClick={() => handleCropSelect(crop)}>
                                <img width={15} src={editIcon} alt="" />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {isDialogOpen && selectedCrop && (
                  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
                    <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[90vh] flex flex-col">
                      <div className="p-6 overflow-y-auto flex-grow">
                        <h2 className="text-2xl font-semibold mb-4">
                          {t("Update Crop Data:")} {selectedCrop}
                        </h2>

                        <form onSubmit={handleSubmitEdit} className="space-y-4">
                          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                            {Object.entries(editedCropData).map(
                              ([key, value]) => (
                                <div key={key} className="flex flex-col">
                                  <label
                                    htmlFor={key}
                                    className="text-sm font-medium text-gray-700 mb-1"
                                  >
                                    {key.replace(/_/g, " ").toUpperCase()}
                                  </label>
                                  <input
                                    id={key}
                                    name={key}
                                    type=""
                                    value={value} // The input value is now tied to editedCropData
                                    onChange={handleInputChange}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                                  />
                                  <span className="text-xs text-gray-500 mt-1">
                                    {t("Original:")} {cropData[key]}
                                  </span>
                                </div>
                              )
                            )}
                          </div>
                        </form>
                      </div>
                      <div className="p-4 bg-gray-100 rounded-b-lg flex justify-end space-x-2">
                        <button
                          type="button"
                          onClick={() => setIsDialogOpen(false)}
                          disabled={isLoading}
                          className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition duration-300"
                        >
                          {t("Cancel")}
                        </button>
                        <button
                          type="submit"
                          onClick={handleSubmitEdit}
                          disabled={isLoading}
                          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300 disabled:opacity-50"
                        >
                          {isLoading ? "Updating..." : "Update Crop"}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex justify-between items-center mt-4">
                  <a
                    className="border py-2 px-4 border-indigo-600 inline-flex items-center gap-x-1 text-sm font-semibold rounded-lg text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                    href="#"
                  >
                    {t("Add Crop")}
                  </a>
                  <nav className="inline-flex space-x-2.5">
                    <button
                      disabled={page <= 1}
                      onClick={handlePrev}
                      className="bg-[#008D06] hover:bg-blue-700 text-white font-semibold items-center py-2 px-4 flex rounded-md"
                    >
                      {t("Prev")}
                      {/* <ChevronLeft size={23} /> Prev */}
                    </button>
                    <button
                      disabled={cropList?.data?.data.length < 15}
                      onClick={() => setPage((s) => s + 1)}
                      className="bg-[#008D06] hover:bg-blue-700 text-white font-semibold py-2 px-5 flex items-center rounded-md"
                    >
                      {t("Next")}
                      {/*  <ChevronRight size={23} /> */}
                    </button>
                  </nav>
                </div>
              </div>
              <div>
                {/* Card */}
                <div className="flex flex-col border rounded-xl p-4 sm:p-6 lg:p-10 dark:border-gray-700">
                  <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                    {t("Add Crop")}
                  </h2>
                  <form>
                    <div className="mt-6 grid gap-4 lg:gap-6">
                      {/* Grid */}
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
                        {/* Input Number */}
                        <div
                          className="py-2 px-3 bg-white border border-[#338FD9] rounded-lg dark:bg-slate-900 dark:border-gray-700"
                          data-hs-input-number=""
                        >
                          <div className="w-full flex justify-between items-center gap-x-5">
                            <div className="grow">
                              <span className="block text-xs text-gray-500 dark:text-gray-400">
                                Name
                              </span>
                              <input
                                {...register("crop_name")}
                                className="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                                type="text"
                                placeholder="Crop Name"
                                data-hs-input-number-input=""
                              />
                            </div>
                          </div>
                        </div>
                        {/* End Input Number */}
                        {/* Input Number */}
                        <div
                          className="py-2 px-3 bg-white border border-[#338FD9]  rounded-lg dark:bg-slate-900 dark:border-gray-700"
                          data-hs-input-number=""
                        >
                          <div className="w-full flex justify-between items-center gap-x-5">
                            <div className="grow">
                              <span className="block text-xs text-gray-500 dark:text-gray-400">
                                Optimal min temperature
                              </span>
                              <input
                                {...register("optimal_min_temperature")}
                                className="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                                type="number"
                                defaultValue={1}
                                data-hs-input-number-input=""
                              />
                            </div>
                            <div className="flex justify-end items-center gap-x-1.5">
                              {/* <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9]  bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-decrement=""
                              >
                                <i className="bi bi-dash-circle"></i>
                              </button>
                              <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-increment=""
                              >
                                <i className="bi bi-plus-circle"></i>
                              </button> */}
                            </div>
                          </div>
                        </div>
                        {/* End Input Number */}
                        {/* Input Number */}
                        <div
                          className="py-2 px-3 bg-white border border-[#338FD9] rounded-lg dark:bg-slate-900 dark:border-gray-700"
                          data-hs-input-number=""
                        >
                          <div className="w-full flex justify-between items-center gap-x-5">
                            <div className="grow">
                              <span className="block text-xs text-gray-500 dark:text-gray-400">
                                optimal max temperature
                              </span>
                              <input
                                {...register("optimal_max_temperature")}
                                className="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                                type="number"
                                defaultValue={1}
                                data-hs-input-number-input=""
                              />
                            </div>
                            <div className="flex justify-end items-center gap-x-1.5">
                              {/* <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-decrement=""
                              >
                                <i className="bi bi-dash-circle"></i>
                              </button>
                              <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-increment=""
                              >
                                <i className="bi bi-plus-circle"></i>
                              </button> */}
                            </div>
                          </div>
                        </div>
                        {/* End Input Number */}
                        {/* Input Number */}
                        <div
                          className="py-2 px-3 bg-white border border-[#338FD9] rounded-lg dark:bg-slate-900 dark:border-gray-700"
                          data-hs-input-number=""
                        >
                          <div className="w-full flex justify-between items-center gap-x-5">
                            <div className="grow">
                              <span className="block text-xs text-gray-500 dark:text-gray-400">
                                optimal min rainfall
                              </span>
                              <input
                                {...register("optimal_min_rainfall")}
                                className="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                                type="number"
                                defaultValue={1}
                                data-hs-input-number-input=""
                              />
                            </div>
                            <div className="flex justify-end items-center gap-x-1.5">
                              {/* <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-decrement=""
                              >
                                <i className="bi bi-dash-circle"></i>
                              </button>
                              <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-increment=""
                              >
                                <i className="bi bi-plus-circle"></i>
                              </button> */}
                            </div>
                          </div>
                        </div>
                        {/* End Input Number */}
                        {/* Input Number */}
                        <div
                          className="py-2 px-3 bg-white border border-[#338FD9] rounded-lg dark:bg-slate-900 dark:border-gray-700"
                          data-hs-input-number=""
                        >
                          <div className="w-full flex justify-between items-center gap-x-5">
                            <div className="grow">
                              <span className="block text-xs text-gray-500 dark:text-gray-400">
                                optimal max rainfall
                              </span>
                              <input
                                {...register("optimal_max_rainfall")}
                                className="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                                type="number"
                                defaultValue={1}
                                data-hs-input-number-input=""
                              />
                            </div>
                            <div className="flex justify-end items-center gap-x-1.5">
                              {/* <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-decrement=""
                              >
                                <i className="bi bi-dash-circle"></i>
                              </button>
                              <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-increment=""
                              >
                                <i className="bi bi-plus-circle"></i>
                              </button> */}
                            </div>
                          </div>
                        </div>
                        <div
                          className="py-2 px-3 bg-white border border-[#338FD9] rounded-lg dark:bg-slate-900 dark:border-gray-700"
                          data-hs-input-number=""
                        >
                          <div className="w-full flex justify-between items-center gap-x-5">
                            <div className="grow">
                              <span className="block text-xs text-gray-500 dark:text-gray-400">
                                absolute_min_temperature
                              </span>
                              <input
                                {...register("absolute_min_temperature")}
                                className="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                                type="number"
                                defaultValue={1}
                                data-hs-input-number-input=""
                              />
                            </div>
                            <div className="flex justify-end items-center gap-x-1.5">
                              {/* <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-decrement=""
                              >
                                <i className="bi bi-dash-circle"></i>
                              </button>
                              <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-increment=""
                              >
                                <i className="bi bi-plus-circle"></i>
                              </button> */}
                            </div>
                          </div>
                        </div>
                        {/* End Input Number */}
                        {/* Input Number */}
                        <div
                          className="py-2 px-3 bg-white border border-[#338FD9] rounded-lg dark:bg-slate-900 dark:border-gray-700"
                          data-hs-input-number=""
                        >
                          <div className="w-full flex justify-between items-center gap-x-5">
                            <div className="grow">
                              <span className="block text-xs text-gray-500 dark:text-gray-400">
                                absolute_max_temperature
                              </span>
                              <input
                                {...register("absolute_max_temperature")}
                                className="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                                type="number"
                                defaultValue={1}
                                data-hs-input-number-input=""
                              />
                            </div>
                            <div className="flex justify-end items-center gap-x-1.5">
                              {/* <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-decrement=""
                              >
                                <i className="bi bi-dash-circle"></i>
                              </button>
                              <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-increment=""
                              >
                                <i className="bi bi-plus-circle"></i>
                              </button> */}
                            </div>
                          </div>
                        </div>
                        {/* End Input Number */}
                        {/* Input Number */}
                        <div
                          className="py-2 px-3 bg-white border border-[#338FD9] rounded-lg dark:bg-slate-900 dark:border-gray-700"
                          data-hs-input-number=""
                        >
                          <div className="w-full flex justify-between items-center gap-x-5">
                            <div className="grow">
                              <span className="block text-xs text-gray-500 dark:text-gray-400">
                                absolute_min_rainfall
                              </span>
                              <input
                                {...register("absolute_min_rainfall")}
                                className="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                                type="number"
                                defaultValue={1}
                                data-hs-input-number-input=""
                              />
                            </div>
                            <div className="flex justify-end items-center gap-x-1.5">
                              {/* <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-decrement=""
                              >
                                <i className="bi bi-dash-circle"></i>
                              </button>
                              <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-increment=""
                              >
                                <i className="bi bi-plus-circle"></i>
                              </button> */}
                            </div>
                          </div>
                        </div>
                        <div
                          className="py-2 px-3 bg-white border border-[#338FD9] rounded-lg dark:bg-slate-900 dark:border-gray-700"
                          data-hs-input-number=""
                        >
                          <div className="w-full flex justify-between items-center gap-x-5">
                            <div className="grow">
                              <span className="block text-xs text-gray-500 dark:text-gray-400">
                                absolute_max_rainfall
                              </span>
                              <input
                                {...register("absolute_max_rainfall")}
                                className="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                                type="number"
                                defaultValue={1}
                                data-hs-input-number-input=""
                              />
                            </div>
                            <div className="flex justify-end items-center gap-x-1.5">
                              {/* <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-decrement=""
                              >
                                <i className="bi bi-dash-circle"></i>
                              </button>
                              <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-increment=""
                              >
                                <i className="bi bi-plus-circle"></i>
                              </button> */}
                            </div>
                          </div>
                        </div>
                        {/* End Input Number */}
                        {/* Input Number */}
                        <div
                          className="py-2 px-3 bg-white border border-[#338FD9] rounded-lg dark:bg-slate-900 dark:border-gray-700"
                          data-hs-input-number=""
                        >
                          <div className="w-full flex justify-between items-center gap-x-5">
                            <div className="grow">
                              <span className="block text-xs text-gray-500 dark:text-gray-400">
                                optimal_min_soil_ph
                              </span>
                              <input
                                {...register("optimal_min_soil_ph")}
                                className="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                                type="number"
                                defaultValue={1}
                                data-hs-input-number-input=""
                              />
                            </div>
                            <div className="flex justify-end items-center gap-x-1.5">
                              {/* <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-decrement=""
                              >
                                <i className="bi bi-dash-circle"></i>
                              </button>
                              <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-increment=""
                              >
                                <i className="bi bi-plus-circle"></i>
                              </button> */}
                            </div>
                          </div>
                        </div>
                        {/* End Input Number */}
                        {/* Input Number */}
                        <div
                          className="py-2 px-3 bg-white border border-[#338FD9] rounded-lg dark:bg-slate-900 dark:border-gray-700"
                          data-hs-input-number=""
                        >
                          <div className="w-full flex justify-between items-center gap-x-5">
                            <div className="grow">
                              <span className="block text-xs text-gray-500 dark:text-gray-400">
                                optimal_max_soil_ph
                              </span>
                              <input
                                {...register("optimal_max_soil_ph")}
                                className="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                                type="number"
                                defaultValue={1}
                                data-hs-input-number-input=""
                              />
                            </div>
                            <div className="flex justify-end items-center gap-x-1.5">
                              {/* <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-decrement=""
                              >
                                <i className="bi bi-dash-circle"></i>
                              </button>
                              <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-increment=""
                              >
                                <i className="bi bi-plus-circle"></i>
                              </button> */}
                            </div>
                          </div>
                        </div>
                        {/* End Input Number */}
                        {/* Input Number */}
                        <div
                          className="py-2 px-3 bg-white border border-[#338FD9] rounded-lg dark:bg-slate-900 dark:border-gray-700"
                          data-hs-input-number=""
                        >
                          <div className="w-full flex justify-between items-center gap-x-5">
                            <div className="grow">
                              <span className="block text-xs text-gray-500 dark:text-gray-400">
                                absolute_min_soil_ph
                              </span>
                              <input
                                {...register("absolute_min_soil_ph")}
                                className="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                                type="number"
                                defaultValue={1}
                                data-hs-input-number-input=""
                              />
                            </div>
                            <div className="flex justify-end items-center gap-x-1.5">
                              {/* <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-decrement=""
                              >
                                <i className="bi bi-dash-circle"></i>
                              </button>
                              <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-increment=""
                              >
                                <i className="bi bi-plus-circle"></i>
                              </button> */}
                            </div>
                          </div>
                        </div>
                        {/* End Input Number */}
                        {/* Input Number */}
                        <div
                          className="py-2 px-3 bg-white border border-[#338FD9] rounded-lg dark:bg-slate-900 dark:border-gray-700"
                          data-hs-input-number=""
                        >
                          <div className="w-full flex justify-between items-center gap-x-5">
                            <div className="grow">
                              <span className="block text-xs text-gray-500 dark:text-gray-400">
                                absolute_max_soil_ph
                              </span>
                              <input
                                {...register("absolute_max_soil_ph")}
                                className="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                                type="number"
                                defaultValue={1}
                                data-hs-input-number-input=""
                              />
                            </div>
                            <div className="flex justify-end items-center gap-x-1.5">
                              {/* <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-decrement=""
                              >
                                <i className="bi bi-dash-circle"></i>
                              </button>
                              <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-increment=""
                              >
                                <i className="bi bi-plus-circle"></i>
                              </button> */}
                            </div>
                          </div>
                        </div>
                        <div
                          className="py-2 px-3 bg-white border border-[#338FD9] rounded-lg dark:bg-slate-900 dark:border-gray-700"
                          data-hs-input-number=""
                        >
                          <div className="w-full flex justify-between items-center gap-x-5">
                            <div className="grow">
                              <span className="block text-xs text-gray-500 dark:text-gray-400">
                                optimal_max_aridity
                              </span>
                              <input
                                {...register("optimal_max_aridity")}
                                className="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                                type="number"
                                defaultValue={1}
                                data-hs-input-number-input=""
                              />
                            </div>
                            <div className="flex justify-end items-center gap-x-1.5">
                              {/* <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-decrement=""
                              >
                                <i className="bi bi-dash-circle"></i>
                              </button>
                              <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-increment=""
                              >
                                <i className="bi bi-plus-circle"></i>
                              </button> */}
                            </div>
                          </div>
                        </div>
                        {/* End Input Number */}
                        <div
                          className="py-2 px-3 bg-white border border-[#338FD9] rounded-lg dark:bg-slate-900 dark:border-gray-700"
                          data-hs-input-number=""
                        >
                          <div className="w-full flex justify-between items-center gap-x-5">
                            <div className="grow">
                              <span className="block text-xs text-gray-500 dark:text-gray-400">
                                optimal_min_aridity
                              </span>
                              <input
                                {...register("optimal_min_aridity")}
                                className="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                                type="number"
                                defaultValue={1}
                                data-hs-input-number-input=""
                              />
                            </div>
                            <div className="flex justify-end items-center gap-x-1.5">
                              {/* <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-decrement=""
                              >
                                <i className="bi bi-dash-circle"></i>
                              </button>
                              <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-increment=""
                              >
                                <i className="bi bi-plus-circle"></i>
                              </button> */}
                            </div>
                          </div>
                        </div>
                        <div
                          className="py-2 px-3 bg-white border border-[#338FD9] rounded-lg dark:bg-slate-900 dark:border-gray-700"
                          data-hs-input-number=""
                        >
                          <div className="w-full flex justify-between items-center gap-x-5">
                            <div className="grow">
                              <span className="block text-xs text-gray-500 dark:text-gray-400">
                                absolute_max_aridity
                              </span>
                              <input
                                {...register("absolute_max_aridity")}
                                className="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                                type="number"
                                defaultValue={1}
                                data-hs-input-number-input=""
                              />
                            </div>
                            <div className="flex justify-end items-center gap-x-1.5">
                              {/* <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-decrement=""
                              >
                                <i className="bi bi-dash-circle"></i>
                              </button>
                              <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-increment=""
                              >
                                <i className="bi bi-plus-circle"></i>
                              </button> */}
                            </div>
                          </div>
                        </div>
                        <div
                          className="py-2 px-3 bg-white border border-[#338FD9] rounded-lg dark:bg-slate-900 dark:border-gray-700"
                          data-hs-input-number=""
                        >
                          <div className="w-full flex justify-between items-center gap-x-5">
                            <div className="grow">
                              <span className="block text-xs text-gray-500 dark:text-gray-400">
                                absolute_min_aridity
                              </span>
                              <input
                                {...register("absolute_min_aridity")}
                                className="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                                type="number"
                                defaultValue={1}
                                data-hs-input-number-input=""
                              />
                            </div>
                            <div className="flex justify-end items-center gap-x-1.5">
                              {/* <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-decrement=""
                              >
                                <i className="bi bi-dash-circle"></i>
                              </button>
                              <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-increment=""
                              >
                                <i className="bi bi-plus-circle"></i>
                              </button> */}
                            </div>
                          </div>
                        </div>
                        <div
                          className="py-2 px-3 bg-white border border-[#338FD9] rounded-lg dark:bg-slate-900 dark:border-gray-700"
                          data-hs-input-number=""
                        >
                          <div className="w-full flex justify-between items-center gap-x-5">
                            <div className="grow">
                              <span className="block text-xs text-gray-500 dark:text-gray-400">
                                altitude_min
                              </span>
                              <input
                                {...register("altitude_min")}
                                className="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                                type="number"
                                defaultValue={1}
                                data-hs-input-number-input=""
                              />
                            </div>
                            <div className="flex justify-end items-center gap-x-1.5">
                              {/* <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-decrement=""
                              >
                                <i className="bi bi-dash-circle"></i>
                              </button>
                              <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-increment=""
                              >
                                <i className="bi bi-plus-circle"></i>
                              </button> */}
                            </div>
                          </div>
                        </div>
                        <div
                          className="py-2 px-3 bg-white border border-[#338FD9] rounded-lg dark:bg-slate-900 dark:border-gray-700"
                          data-hs-input-number=""
                        >
                          <div className="w-full flex justify-between items-center gap-x-5">
                            <div className="grow">
                              <span className="block text-xs text-gray-500 dark:text-gray-400">
                                altitude_max
                              </span>
                              <input
                                {...register("altitude_max")}
                                className="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                                type="number"
                                defaultValue={1}
                                data-hs-input-number-input=""
                              />
                            </div>
                            <div className="flex justify-end items-center gap-x-1.5">
                              {/* <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-decrement=""
                              >
                                <i className="bi bi-dash-circle"></i>
                              </button>
                              <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-increment=""
                              >
                                <i className="bi bi-plus-circle"></i>
                              </button> */}
                            </div>
                          </div>
                        </div>

                        <div
                          className="py-2 px-3 bg-white border border-[#338FD9] rounded-lg dark:bg-slate-900 dark:border-gray-700"
                          data-hs-input-number=""
                        >
                          <div className="w-full flex justify-between items-center gap-x-5">
                            <div className="grow">
                              <span className="block text-xs text-gray-500 dark:text-gray-400">
                                life_cycle
                              </span>
                              <input
                                {...register("life_cycle")}
                                className="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0 dark:text-white"
                                defaultValue="life-cycle"
                              />
                            </div>
                            <div className="flex justify-end items-center gap-x-1.5">
                              {/* <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-decrement=""
                              >
                                <i className="bi bi-dash-circle"></i>
                              </button>
                              <button
                                type="button"
                                className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-[#338FD9] bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                data-hs-input-number-increment=""
                              >
                                <i className="bi bi-plus-circle"></i>
                              </button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* End Grid */}
                    <div className="mt-6 grid">
                      <button
                        onClick={handleSubmit(onSubmit)}
                        type="submit"
                        className="inline-flex justify-center items-center gap-x-3 text-center bg-[#008D06] hover:bg-blue-700 border border-transparent text-sm lg:text-base text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4 dark:focus:ring-offset-gray-800"
                      >
                        {t("Save All")}
                      </button>
                    </div>
                  </form>
                </div>
                {/* End Card */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainDashboard>
  );
}
