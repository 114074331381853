import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosPrivateOid } from "../api/axiosPrivate";
import { Coins } from "lucide-react";
import PricingCard from "../components/PricingCard";
import MainDashboard from "../components/dashboards/main_dashboard";
import { usePayment } from "../context/Payment";
import { useTranslation } from "react-i18next";

const CurrencyDetailsPage = () => {
  const [price, setPrice] = useState([]);
  const { t } = useTranslation("home");
  //@ts-ignore
  const { credits } = usePayment();
  async function getProduct() {
    axiosPrivateOid<any>({
      method: "GET",
      url: "/user/single/topup/product-list",
    })
      .then((data) => {
        setPrice(data?.data?.products);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  const navigate = useNavigate();
  useEffect(() => {
    getProduct();
  }, []);

  return (
    <MainDashboard>
      <div className="container mx-auto p-6">
        <h1 className="text-3xl font-bold mb-6">{t("Credits Details")}</h1>
        <div className="bg-gray-100 p-6 rounded-lg shadow-md mb-8">
          <div className="flex items-center space-x-4">
            <Coins className="text-yellow-400" size={48} />
            <div>
              <p className="text-xl font-semibold">
                {t("Your Current Balance")}
              </p>
              <p className="text-4xl font-bold text-gray-800">{credits}</p>
            </div>
          </div>
        </div>
        <h2 className="text-2xl font-bold mb-4">
          {t("Purchase More credits")}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {price?.map((product: any, index: any) => (
            <div
              onClick={() => {
                navigate(
                  `/checkout?&priceId=${encodeURIComponent(product.price_id)}`
                );
              }}
            >
              <PricingCard
                key={product.price_id}
                product={product}
                isPopular={index === 1}
              />
            </div>
          ))}
        </div>
      </div>
    </MainDashboard>
  );
};

export default CurrencyDetailsPage;
