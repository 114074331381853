// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";

import ReactApexChart from "react-apexcharts";
import { useCustomTheme } from "../context/ThemeProvider";
import ProgressBarHome from "./ProgressBarHome";

type GradientBarChart = {
  categories: string[];
  scores: any;
  composite?: boolean;
};

const GradientBarchartLivestock = ({
  categories,
  scores,
  idle,
}: // composite,
GradientBarChart) => {
  // console.log("GradientBarChart", scores);
  const { customTheme } = useCustomTheme();
  // console.log(customTheme, "theme");

  const pickColor = (score) => {
    if (score >= 0 && score <= 30) {
      return "#6ebd5f";
    } else if (score > 30 && score <= 40) {
      return "#b0bf58";
    } else if (score > 40 && score <= 50) {
      return "#f6ca5a";
    } else if (score > 50 && score <= 60) {
      return "#fac25d";
    } else if (score > 60 && score <= 70) {
      return "#fa9359";
    } else if (score > 70 && score <= 100) {
      return "#fc5874";
    } else {
      return "#e5e7eb";
    }
  };

  return (
    <div id="chart" style={{ marginTop: "-1.5rem", color: "black" }}>
      <div className="flex flex-col w-[100%]">
        <div>
          <p className="text-xs p-1 font-medium text-[#404040] dark:text-[#FFFFF7]">
            {categories[0]}
          </p>
          {/* <div class="w-full h-6 bg-gray-200 rounded-full dark:bg-gray-700">
            <div
              class="h-6  rounded-full dark:bg-blue-500"
              style={{
                // width: `${scores[0].data[0]}%`,
                backgroundColor: `${pickColor(scores[0].data[0])}`,
                width: !idle ? "0%" : `${scores[0].data[0]}%`,
              }}
            ></div>
          </div> */}
          <ProgressBarHome
            idle={idle}
            category={categories[0]}
            score={scores[0].data[0]}
          />
        </div>
        {categories.length > 1 && (
          <div>
            <p className="text-xs p-1 font-medium text-[#404040] dark:text-[#FFFFF7]">
              {categories[1]}
            </p>
            {/* <div class="w-full h-6 bg-gray-200 rounded-full dark:bg-gray-700 ">
              <div
                class="h-6 rounded-full dark:bg-blue-500"
                style={{
                  backgroundColor: `${pickColor(scores[0].data[1])}`,
                  // width: `${scores[0].data[1]}%`,
                  width: !idle ? "0%" : `${scores[0].data[1]}%`,
                }}
              ></div>
            </div> */}
            <ProgressBarHome
              idle={idle}
              category={categories[1]}
              score={scores[0].data[1]}
            />
          </div>
        )}
      </div>
      {/* <ReactApexChart
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore

        options={{
          ...options, // Keep the existing options

          plotOptions: {
            bar: {
              horizontal: true,
              borderRadius: 10,
              colors: {
                // Specify an array of colors here
                ranges: [
                  { from: 0, to: 30, color: "#00ac3e" },
                  { from: 30, to: 70, color: "#ffc125" },
                  { from: 70, to: 100, color: "#ff4040" },
                ],
              },
            },
          },
        }}
        series={scores}
        type="bar"
        height={150}
      /> */}
    </div>
  );
};

export default GradientBarchartLivestock;
